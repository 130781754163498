@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700');

body,
div,
dl,
dt,
dd,
pre,
code,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset,
img,
abbr {
  border: 0;
}

address,
caption,
cite,
code,
dfn,
th,
var {
  font-style: normal;
  font-weight: normal;
}

caption,
th {
  text-align: left;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

legend {
  color: #000;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
}

img {
  max-width: 100%;
  height: auto;
}

.wrapper {
  max-width: 1170px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}

body {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: #333;
}

#form_modal {
  display: none;
  width: 30%;
  border: 2px solid #e5017c;
}

#form_modal .modal-content {
  text-align: center;
}

#form_modal .modal-content .modal-header {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 10px;
}

#form_modal .modal-content .modal-header h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  padding-top: 10px;
  padding-bottom: 5px;
  font-size: 22px;
  line-height: 24px;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: 900;
}

#form_modal .modal-content .policy {
  padding-bottom: 15px;
}

#form_modal .modal-content .policy a {
  color: #333;
  font-weight: 700;
}

#form_modal .modal-content .policy_tel {
  display: none;
  padding-top: 15px;
}

#form_modal .modal-content .policy_tel a {
  color: #333;
  font-weight: 700;
}

#form_modal .modal-content form input {
  width: 90%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  padding: 10px 10px;
  outline: none;
  border: none;
  color: #333;
  font-size: 13px;
  transition: all 0.2s ease-in-out;
  border-radius: 10px;
  text-decoration: none;
  letter-spacing: 0.8px;
  margin-bottom: 20px;
}

#form_modal .modal-content form textarea {
  width: 90%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  padding: 10px 10px;
  outline: none;
  border: none;
  color: #333;
  font-size: 13px;
  transition: all 0.2s ease-in-out;
  border-radius: 10px;
  text-decoration: none;
  letter-spacing: 0.8px;
  margin-bottom: 20px;
  height: 80px;
}

#form_modal .modal-content .modal-footer {
  display: flex;
  justify-content: center;
}

#form_modal .modal-content .modal-footer button {
  border: none;
  padding: 9px 15px;
  padding-bottom: 10px;
  margin-right: 10px;
  background: #e5017c;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
  font-size: 12px;
  border-radius: 30px;
  outline: none;
}

#form_modal .modal-content .modal-footer a {
  margin: 0 5px 0 5px;
  color: #610000;
  outline: none;
}

.info_header {
  background: #fff;
  width: 100%;
  position: fixed;
  z-index: 100;
  border-bottom: 1px solid #e5017c;
  box-shadow: 0px 7px 5px 0px rgba(50, 50, 50, 0.2);
}

.info_header .contact_information {
  background: linear-gradient(#F5F5F5, #F5F5F5);
  padding: 8px 0;
  margin-bottom: 7px;
  border-bottom: 1px solid #e5e5e5;
}

.info_header .contact_information .container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  justify-content: space-between;
}

.info_header .contact_information .container .block_phone {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(50% - 30px);
  display: flex;
  align-items: center;
  padding-right: 50px;
}

.info_header .contact_information .container .block_phone .map {
  display: flex;
  align-items: center;
  font-size: 15px;
  padding-right: 10px;
}

.info_header .contact_information .container .block_phone .map .fa-clock-o {
  color: #333;
  font-size: 20px;
  padding-right: 7px;
}

.info_header .contact_information .container .block_phone .info_cont {
  display: flex;
  align-items: center;
  margin-top: 2px;
}

.info_header .contact_information .container .block_phone .info_cont .fa {
  font-size: 19px;
  color: #333;
  padding-right: 5px;
  line-height: 0;
  transform: rotate(20deg);
}

.info_header .contact_information .container .block_phone .info_cont a {
  text-decoration: none;
  color: #333;
  font-size: 17px;
  margin-top: -2px;
  margin-right: 10px;
}

.info_header .contact_information .container .request_call {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(50% - 30px);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.info_header .contact_information .container .request_call .callmeback {
  margin-right: 10px;
}

.info_header .contact_information .container .request_call .callmeback a {
  text-decoration: none;
  color: #fff;
  background: #e5017c;
  padding: 8px 12px;
  border-radius: 30px;
  outline: none;
}

.info_header .contact_information .container .request_call .basket {
  border: 1px solid #e5017c;
  padding: 4px 10px;
  border-radius: 30px;
}

.info_header .contact_information .container .request_call .basket a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
}

.info_header .contact_information .container .request_call .basket a img {
  width: 22px;
  height: 22px;
  margin-right: 7px;
  padding-top: 1px;
  line-height: 0;
}

.info_header .contact_information .container .request_call .basket a .goods {
  padding-right: 10px;
}

.info_header .contact_information .container .request_call .entrance {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  position: relative;
}

.info_header .contact_information .container .request_call .entrance:before {
  bottom: 22px;
  left: 10px;
  content: '';
  position: absolute;
  width: 92%;
  border-bottom: 1px dotted #4a4a4a;
}

.info_header .contact_information .container .request_call .entrance a {
  text-decoration: none;
  color: #4a4a4a;
  padding: 2px 0;
  font-size: 15px;
}

.info_header .contact_information .container .request_call .entrance a:hover {
  color: #e5017c;
}

.info_header .container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  justify-content: center;
}

.info_header .container .navigation_container {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(100% - 30px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s ease-in-out;
}

.info_header .container .navigation_container .logo a {
  text-decoration: none;
}

.info_header .container .navigation_container .logo a img {
  transition: all 0.5s ease-in-out;
  width: 100%;
  height: auto;
}

.info_header .container .navigation_container .navigation {
  display: flex;
}

.info_header .container .navigation_container .navigation ul.nav {
  display: inline-flex;
  padding: 0;
  margin: 0;
}

.info_header .container .navigation_container .navigation ul.nav li {
  padding: 0;
  margin: 0;
  list-style: none;
  padding: 0 5px;
}

.info_header .container .navigation_container .navigation ul.nav li a {
  text-decoration: none;
  color: #4a4a4a;
  letter-spacing: 0.8px;
  font-size: 16px;
  padding: 33px 5px 38px 5px;
  cursor: pointer;
  text-transform: uppercase;
  position: relative;
  transition: all 0.2s ease-in-out;
  outline: none;
  font-weight: 500;
}

.info_header .container .navigation_container .navigation ul.nav li a:hover {
  color: #e5017c;
}

.info_header .container .navigation_container .navigation ul.nav .active {
  border-bottom: 2px solid #e5017c;
}

.info_header .container .navigation_container .navigation ul.nav .active a {
  color: #e5017c;
}

.info_header .container .navigation_height .logo a img {
  width: 80%;
  height: auto;
}

.info_header .navbar-header {
  display: none;
  justify-content: space-between;
  align-items: center;
  z-index: 1002;
}

.info_header .navbar-header .logo a {
  text-decoration: none;
}

.info_header .navbar-header .logo a img {
  width: 100px;
  height: auto;
}

.info_header .navbar-header .basket {
  border: 1px solid #e5017c;
  padding: 4px 10px;
  border-radius: 30px;
}

.info_header .navbar-header .basket a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
}

.info_header .navbar-header .basket a img {
  width: 18px;
  height: 18px;
  margin-right: 7px;
  padding-top: 1px;
  line-height: 0;
}

.info_header .navbar-header .basket a p {
  padding-top: 2px;
}

.info_header .navbar-header .menu-trigger,
.info_header .navbar-header .menu-trigger span {
  transition: all .4s;
  box-sizing: border-box;
  z-index: 1002;
}

.info_header .navbar-header .menu-trigger {
  position: relative;
  width: 40px;
  height: 25px;
}

.info_header .navbar-header .menu-trigger span {
  position: absolute;
  right: 0;
  width: 100%;
  height: 4px;
  background-color: #333;
  border-radius: 4px;
}

.info_header .navbar-header .menu-trigger span:nth-of-type(1) {
  top: 0;
}

.info_header .navbar-header .menu-trigger span:nth-of-type(2) {
  top: 10px;
}

.info_header .navbar-header .menu-trigger span:nth-of-type(3) {
  bottom: 0;
}

.info_header #menu02.active span:nth-of-type(1) {
  transform: translateY(10px) rotate(-315deg);
}

.info_header #menu02.active span:nth-of-type(2) {
  opacity: 0;
}

.info_header #menu02.active span:nth-of-type(3) {
  transform: translateY(-12px) rotate(315deg);
}

.info_header .burger-menu {
  display: flex;
  background: #fff;
  width: 100%;
  display: none;
  box-shadow: 5px 3px 5px rgba(191, 191, 191, 0.7);
  z-index: 1001;
  height: 500px;
  overflow: auto;
  content: "";
}

.info_header .burger-menu .navi .nav li {
  list-style: none;
  padding: 15px 0;
  padding-left: 15px;
  transition: all ease-out 0.2s;
  border-bottom: 1px solid #DEDEDE;
  overflow: hidden;
  text-align: center;
}

.info_header .burger-menu .navi .nav li a {
  text-decoration: none;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  color: #333;
}

.info_header .burger-menu .navi .request_call {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}

.info_header .burger-menu .navi .request_call .callmeback {
  margin-right: 10px;
}

.info_header .burger-menu .navi .request_call .callmeback a {
  text-decoration: none;
  color: #fff;
  background: #e5017c;
  padding: 8px 12px;
  border-radius: 30px;
  outline: none;
}

.info_header .burger-menu .navi .request_call .basket {
  border: 1px solid #e5017c;
  padding: 4px 10px;
  border-radius: 30px;
}

.info_header .burger-menu .navi .request_call .basket a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
}

.info_header .burger-menu .navi .request_call .basket a img {
  width: 22px;
  height: 22px;
  margin-right: 7px;
  padding-top: 1px;
  line-height: 0;
}

.info_header .burger-menu .navi .request_call .basket a .goods {
  padding-right: 10px;
}

.info_jevea_water {
  padding-top: 170px;
}

.info_jevea_water .caption {
  text-align: center;
  padding-bottom: 30px;
}

.info_jevea_water .caption h1 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  font-size: 30px;
  font-weight: 700;
  line-height: 34px;
  text-shadow: 1.1px 1.1px rgba(0, 0, 0, 0.5);
}

.info_jevea_water .caption h1 span {
  color: #e5017c;
}

.info_jevea_water .caption h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  font-size: 20px;
  line-height: 34px;
  padding-top: 10px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.1);
}

.info_jevea_water .container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  justify-content: center;
}

.info_jevea_water .container .block {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(50% - 30px);
  text-align: center;
}

.info_living_crystal {
  padding-bottom: 30px;
}

.info_living_crystal .container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.info_living_crystal .container .block {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(100% - 30px);
}

.info_living_crystal .container .block img {
  width: 100%;
  height: auto;
  line-height: 0;
}

.info_living_crystal .container .block h1 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  padding-top: 30px;
  text-align: center;
  color: #e5017c;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
}

.info_living_crystal .container .block h1 span {
  color: #333;
  padding-left: 5px;
  line-height: 20px;
}

.info_living_crystal .container .block h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  padding-top: 15px;
  text-align: center;
  color: #e5017c;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
}

.info_living_crystal .container .block h2 span {
  color: #333;
  line-height: 20px;
}

.info_living_crystal .container .block h3 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  padding-top: 15px;
  text-align: center;
  color: #333;
  text-transform: uppercase;
  font-weight: 400;
  line-height: 20px;
  font-size: 16px;
}

.info_background_video {
  position: relative;
  width: 100%;
  height: 350px;
  overflow: hidden;
  line-height: 0;
  margin-bottom: 50px;
  box-shadow: 8px 8px 8px -5px rgba(50, 50, 50, 0.45);
}

.info_background_video #video-bg img {
  display: none;
}

.info_background_video .container .block {
  position: absolute;
  content: "";
  top: 19%;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 2;
  text-align: center;
}

.info_background_video .container .block .text {
  padding: 20px 20px;
  display: inline-block;
  border-top-right-radius: 2%;
  border-top-left-radius: 2%;
  border-bottom-left-radius: 2%;
  border-bottom-right-radius: 2%;
}

.info_background_video .container .block .text p {
  color: #fff;
  line-height: 2.2;
  font-size: 27px;
  cursor: default;
  line-height: 36px;
  text-shadow: 1.5px 1.5px rgba(0, 0, 0, 0.5);
}

.info_background_video .container .block .text p span {
  font-weight: 700;
  font-size: 28px;
}

.info_background_video .container .callmeback {
  position: absolute;
  content: "";
  top: 65%;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 2;
  text-align: center;
}

.info_background_video .container .callmeback a {
  text-decoration: none;
  color: #fff;
  padding: 5px 25px;
  font-size: 18px;
  border-radius: 30px;
  outline: none;
  text-shadow: 1.5px 1.5px rgba(0, 0, 0, 0.5);
  border: 1px solid #fff;
}

.info_advantages_drinking_water {
  padding-bottom: 40px;
}

.info_advantages_drinking_water .caption {
  padding-top: 10px;
  padding-bottom: 50px;
}

.info_advantages_drinking_water .caption h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  font-size: 25px;
  line-height: 26px;
  font-weight: 700;
  color: #333;
  text-align: center;
}

.info_advantages_drinking_water .caption h2 span {
  color: #e5017c;
}

.info_advantages_drinking_water .container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.info_advantages_drinking_water .container .block {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(33.333333333333336% - 30px);
  padding-bottom: 40px;
}

.info_advantages_drinking_water .container .block .img img {
  width: 100%;
  height: auto;
}

.info_advantages_drinking_water .container .block .text {
  box-shadow: 0 7px 30px 0 rgba(30, 37, 54, 0.08);
  padding: 0 15px 15px 15px;
}

.info_advantages_drinking_water .container .block .text h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  border-bottom: 1px solid #ececec;
  text-align: center;
  padding: 10px 0;
  letter-spacing: 0.4px;
  font-weight: 700;
}

.info_advantages_drinking_water .container .block .text p {
  padding-top: 10px;
  letter-spacing: 0.4px;
}

.info_advantages_drinking_water .container .block .text p span {
  font-weight: 700;
  color: #e5017c;
  padding-right: 3px;
}

.info_advantages_drinking_water .container .block .text p .bold {
  color: #333;
  font-weight: 700;
}

.background_substrate {
  background: url(../img/ppicture2.png) no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  height: 250px;
}

.info_natural_mineral_composition {
  padding-bottom: 50px;
}

.info_natural_mineral_composition .caption {
  padding-top: 50px;
  padding-bottom: 60px;
}

.info_natural_mineral_composition .caption h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  font-size: 25px;
  line-height: 26px;
  font-weight: 700;
  color: #333;
  text-align: center;
}

.info_natural_mineral_composition .caption h2 span {
  color: #e5017c;
}

.info_natural_mineral_composition .container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  justify-content: center;
}

.info_natural_mineral_composition .container .block {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(41.66666666666667% - 30px);
}

.info_natural_mineral_composition .container .block .table {
  border: 1px solid #ddd;
  width: 100%;
}

.info_natural_mineral_composition .container .block .table tbody:nth-child(1) tr th {
  padding: 5px 0;
  text-align: center;
}

.info_natural_mineral_composition .container .block .table tbody:nth-child(1) tr th strong {
  font-weight: 700;
  font-size: 16px;
}

.info_natural_mineral_composition .container .block .table tbody tr {
  border-bottom: 1px solid #ddd;
}

.info_natural_mineral_composition .container .block .table tbody tr td {
  border-right: 1px solid #ddd;
  padding: 8px;
  font-size: 14px;
}

.info_natural_mineral_composition .container .block p {
  text-align: center;
  padding-top: 15px;
  padding-bottom: 10px;
}

.info_natural_mineral_composition .container .block .img {
  text-align: center;
}

.info_natural_mineral_composition .container .block .img img {
  margin: 0 7px;
}

.info_natural_mineral_composition .container .block_img {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(33.333333333333336% - 30px);
}

.info_natural_mineral_composition .container .block_img img {
  margin-top: -25px;
  border-radius: 5%;
}

.background_underlay {
  background: url(../img/photo_2017-10-26_18-38-10.jpg) no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  height: 250px;
}

.info_how_order {
  padding-bottom: 50px;
}

.info_how_order .caption {
  padding-top: 50px;
  padding-bottom: 40px;
}

.info_how_order .caption h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  font-size: 25px;
  line-height: 26px;
  font-weight: 700;
  color: #333;
  text-align: center;
}

.info_how_order .container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  justify-content: center;
}

.info_how_order .container .block {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(33.333333333333336% - 30px);
  margin-bottom: 20px;
  box-shadow: 0 7px 30px 0 rgba(30, 37, 54, 0.05);
  padding: 15px 15px 15px 15px;
  display: flex;
  flex-direction: column;
}

.info_how_order .container .block .img {
  text-align: center;
  position: relative;
  overflow: hidden;
}

.info_how_order .container .block .img h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  padding: 20px 0;
  font-size: 20px;
  height: auto;
  position: absolute;
  top: 35%;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: rgba(10, 10, 0, 0.5);
  color: #fff;
}

.info_how_order .container .block .content h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  border-bottom: 1px solid #ececec;
  text-align: center;
  padding: 10px 0;
  letter-spacing: 0.4px;
  font-weight: 700;
}

.info_how_order .container .block .content .text p:nth-child(1) {
  text-align: center;
  font-size: 15px;
  padding-top: 5px;
}

.info_how_order .container .block .content .text a {
  text-align: center;
  text-decoration: none;
  font-size: 15px;
  display: block;
  color: #e5017c;
  padding-top: 5px;
  border-bottom: 1px solid #ececec;
  padding-bottom: 10px;
  font-weight: 700;
}

.info_how_order .container .block .content .text a:nth-child(2) {
  border-bottom: none;
}

.info_how_order .container .block .content .text p {
  padding: 10px 0 10px 0;
}

.info_how_order .container .block .btn {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 10px;
  margin-top: auto;
}

.info_how_order .container .block .btn a {
  background: #e5017c;
  text-decoration: none;
  color: #fff;
  padding: 8px 12px 9px 12px;
  border-radius: 5px;
}

.info_how_order .container .block_catalog {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(33.333333333333336% - 30px);
  margin-bottom: 20px;
  box-shadow: 0 7px 30px 0 rgba(30, 37, 54, 0.05);
  padding: 15px 15px 15px 15px;
  display: flex;
  flex-direction: column;
}

.info_how_order .container .block_catalog .img {
  text-align: center;
  position: relative;
  overflow: hidden;
}

.info_how_order .container .block_catalog .img h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  padding: 20px 0;
  font-size: 20px;
  height: auto;
  position: absolute;
  top: 35%;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: rgba(10, 10, 0, 0.5);
  color: #fff;
}

.info_how_order .container .block_catalog .content .text p {
  padding-top: 5px;
}

.info_how_order .container .block_catalog .content .text p a {
  text-decoration: none;
  color: #e5017c;
  font-weight: 700;
}

.info_how_order .container .block_catalog .content .text p span {
  font-weight: 700;
}

.info_how_order .container .block_catalog .content .text p .attention {
  text-align: center;
  display: block;
  padding-bottom: 3px;
}

.info_how_order .container .block_catalog .btn {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 10px;
  margin-top: auto;
}

.info_how_order .container .block_catalog .btn a {
  background: #e5017c;
  text-decoration: none;
  color: #fff;
  padding: 8px 12px 9px 12px;
  border-radius: 5px;
}

.background_payment_prices {
  background: url(../img/listya-rosa.orig.jpg) no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  height: 250px;
}

.info_payment_prices {
  padding-bottom: 50px;
}

.info_payment_prices .caption {
  padding-top: 50px;
  padding-bottom: 60px;
}

.info_payment_prices .caption h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  font-size: 26px;
  line-height: 26px;
  font-weight: 700;
  color: #333;
  text-align: center;
}

.info_payment_prices .caption h2 span {
  color: #e5017c;
}

.info_payment_prices .container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.info_payment_prices .container .block {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(50% - 30px);
  padding-bottom: 30px;
}

.info_payment_prices .container .block .images {
  display: flex;
  align-items: center;
}

.info_payment_prices .container .block .images .img {
  align-items: center;
  background: #e5017c;
  display: inline-flex;
  justify-content: center;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  line-height: 0;
}

.info_payment_prices .container .block .images .img img {
  line-height: 0;
}

.info_payment_prices .container .block .images h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  color: #e5017c;
  font-size: 18px;
  font-weight: 700;
  padding-left: 10px;
}

.info_payment_prices .container .block ul {
  padding: 0;
  margin: 0;
  padding-top: 10px;
}

.info_payment_prices .container .block ul li {
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 15px;
  position: relative;
  padding-left: 17px;
  padding-top: 5px;
}

.info_payment_prices .container .block ul li:before {
  position: absolute;
  content: "";
  top: 35%;
  left: 0;
  background: #e5017c;
  border-radius: 50%;
  width: 8px;
  height: 8px;
}

.info_payment_prices .container .block ul li span {
  font-weight: 700;
}

.info_payment_prices .container .block h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  padding-top: 10px;
  color: #e5017c;
  font-weight: 700;
  font-size: 15px;
}

.info_payment_prices .container .block p {
  padding-top: 5px;
  font-size: 15px;
}

.info_payment_prices .container .block p span {
  font-weight: 700;
}

.background_terms_delivery {
  background: url(../img/water_drops_04.jpg) no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  height: 250px;
}

.info_terms_delivery {
  padding-bottom: 50px;
}

.info_terms_delivery .caption {
  padding-top: 50px;
  padding-bottom: 60px;
}

.info_terms_delivery .caption h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  font-size: 25px;
  line-height: 26px;
  font-weight: 700;
  color: #333;
  text-align: center;
}

.info_terms_delivery .caption h2 span {
  color: #e5017c;
}

.info_terms_delivery .container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.info_terms_delivery .container .block {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(50% - 30px);
}

.info_terms_delivery .container .block h2 {
  color: #e5017c;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  padding: 15px 0 10px 0;
}

.info_terms_delivery .container .block p {
  font-size: 15px;
  line-height: 20px;
}

.info_terms_delivery .container .block p span {
  font-weight: 700;
}

.info_terms_delivery .container .block ul {
  padding: 0;
  margin: 0;
}

.info_terms_delivery .container .block ul li {
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 15px;
  position: relative;
  padding-left: 17px;
  padding-top: 5px;
}

.info_terms_delivery .container .block ul li:before {
  position: absolute;
  content: "";
  top: 50%;
  left: 0;
  background: #e5017c;
  border-radius: 50%;
  width: 8px;
  height: 8px;
}

.background_news {
  background: url(../img/Photoshop_Submerged.jpg) no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  height: 250px;
}

.info_news {
  padding-bottom: 50px;
}

.info_news .caption {
  padding-top: 50px;
  padding-bottom: 60px;
}

.info_news .caption h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  font-size: 25px;
  font-weight: 700;
  color: #333;
  text-align: center;
}

.info_news .caption h2 span {
  color: #e5017c;
}

.info_news .container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  justify-content: center;
}

.info_news .container .block {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(33.333333333333336% - 30px);
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0px 5px 22px 0px rgba(30, 37, 54, 0.08);
}

.info_news .container .block .img a {
  text-decoration: none;
  outline: none;
}

.info_news .container .block .img a img {
  width: 100%;
  border-bottom: 4px solid #e5017c;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.info_news .container .block .text {
  padding-left: 10px;
  padding-right: 3px;
}

.info_news .container .block .text .new_z {
  text-decoration: none;
}

.info_news .container .block .text .new_z h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  letter-spacing: 0.4px;
  font-weight: 700;
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #333;
}

.info_news .container .block .text p {
  padding-top: 10px;
  border-top: 1px solid #ECECEC;
  font-size: 14px;
}

.info_news .container .block .btn {
  margin-top: auto;
  text-align: center;
}

.info_news .container .block .btn .button {
  display: inline-block;
  margin-top: 25px;
  margin-bottom: 10px;
  background: #e5017c;
  text-decoration: none;
  color: #fff;
  padding: 8px 12px 9px 12px;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
}

.info_news .read_news {
  text-align: center;
  width: 100%;
  margin-top: 50px;
}

.info_news .read_news .button {
  text-decoration: none;
  background: #e5017c;
  transition: all 0.2s ease-in-out;
  color: #fff;
  padding: 8px 12px 9px 12px;
  border-radius: 5px;
}

.background_articles {
  background: url(../img/ladybug_insect_grass.jpg) no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  height: 250px;
}

.info_stats {
  padding-bottom: 50px;
}

.info_stats .caption {
  padding-top: 50px;
  padding-bottom: 60px;
}

.info_stats .caption h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  font-size: 25px;
  font-weight: 700;
  color: #333;
  text-align: center;
}

.info_stats .caption h2 span {
  color: #e5017c;
}

.info_stats .container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  justify-content: center;
}

.info_stats .container .block {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(33.333333333333336% - 30px);
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0px 5px 22px 0px rgba(30, 37, 54, 0.08);
}

.info_stats .container .block .img a {
  text-decoration: none;
  outline: none;
}

.info_stats .container .block .img a img {
  width: 100%;
  border-bottom: 4px solid #e5017c;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.info_stats .container .block .text {
  padding-left: 10px;
  padding-right: 3px;
}

.info_stats .container .block .text .new_z {
  text-decoration: none;
}

.info_stats .container .block .text .new_z h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  letter-spacing: 0.4px;
  font-weight: 700;
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #333;
}

.info_stats .container .block .text p {
  padding-top: 10px;
  border-top: 1px solid #ECECEC;
  font-size: 14px;
  padding-bottom: 10px;
}

.info_stats .container .block .text p:last-child {
  font-style: italic;
}

.info_stats .container .block .btn {
  margin-top: auto;
  text-align: center;
}

.info_stats .container .block .btn .button {
  display: inline-block;
  margin-top: 25px;
  margin-bottom: 10px;
  background: #e5017c;
  text-decoration: none;
  color: #fff;
  padding: 8px 12px 9px 12px;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
}

.info_stats .read_news {
  text-align: center;
  width: 100%;
  margin-top: 50px;
}

.info_stats .read_news .button {
  text-decoration: none;
  background: #e5017c;
  transition: all 0.2s ease-in-out;
  color: #fff;
  padding: 8px 12px 9px 12px;
  border-radius: 5px;
}

.background_reviews_water {
  background: url(../img/sea-water-1666319_1920.jpg) no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  height: 250px;
}

.info_block_slider {
  padding-top: 50px;
  padding-bottom: 50px;
}

.info_block_slider .caption {
  padding-bottom: 60px;
}

.info_block_slider .caption h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  font-size: 25px;
  line-height: 26px;
  font-weight: 700;
  color: #333;
  text-align: center;
}

.info_block_slider .caption h2 span {
  color: #e5017c;
}

.info_block_slider .container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  justify-content: center;
}

.info_block_slider .container .block {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(66.66666666666667% - 30px);
}

.info_block_slider .container .block .block_sld {
  position: relative;
}

.info_block_slider .container .block .block_sld .sld {
  padding: 0 5px;
}

.info_block_slider .container .block .block_sld .sld .img {
  text-align: center;
  position: relative;
  padding-bottom: 5px;
  margin-bottom: 5px;
  padding-top: 10px;
}

.info_block_slider .container .block .block_sld .sld .img img {
  padding: 2px;
  margin: 0 auto;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(229, 1, 124, 0.3);
  width: 100px;
  height: auto;
}

.info_block_slider .container .block .block_sld .sld .img h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  font-weight: 700;
  font-size: 16px;
  color: #e5017c;
  padding: 10px 0 5px 0;
}

.info_block_slider .container .block .block_sld .sld .img p {
  padding: 0 0 5px 0;
}

.info_block_slider .container .block .block_sld .sld .img:before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  right: 0;
  width: 30px;
  height: 3px;
  background: #e5017c;
  margin: 0 auto;
  box-shadow: 0 0 10px rgba(229, 1, 124, 0.3);
}

.info_block_slider .container .block .block_sld .sld .text {
  border-bottom: 2px solid #e5017c;
  padding-bottom: 15px;
}

.info_block_slider .container .block .block_sld .sld .text .content {
  text-align: center;
}

.info_block_slider .container .block .block_sld .sld .text .author {
  padding-top: 10px;
  display: flex;
  justify-content: space-around;
}

.info_block_slider .container .block .block_sld .sld .text .author .info span {
  font-weight: 700;
}

.info_block_slider .container .block .block_sld .sld .text .author .info a {
  text-decoration: none;
  color: #e5017c;
}

.info_block_slider .container .block .block_sld .sld .text .author .more {
  display: flex;
  align-items: center;
}

.info_block_slider .container .block .block_sld .sld .text .author .more a {
  font-weight: 700;
  padding-right: 5px;
  display: block;
  text-align: center;
  text-decoration: none;
  color: #e5017c;
}

.info_block_slider .container .block .block_sld .sld .text .author .more .fa {
  color: #e5017c;
}

.info_block_slider .container .block .block_sld .prev {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  content: "";
  top: 45%;
  left: -15%;
  background: #FFF7EB;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  z-index: 80;
  box-shadow: 0 0 10px rgba(229, 1, 124, 0.6);
  cursor: pointer;
}

.info_block_slider .container .block .block_sld .prev .fa {
  font-size: 15px;
  color: #e5017c;
}

.info_block_slider .container .block .block_sld .next {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  content: "";
  top: 45%;
  right: -15%;
  background: #FFF7EB;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  z-index: 80;
  box-shadow: 0 0 10px rgba(229, 1, 124, 0.6);
  cursor: pointer;
}

.info_block_slider .container .block .block_sld .next .fa {
  font-size: 15px;
  color: #e5017c;
}

.info_block_slider .container .block .block_sld .slick-dots {
  top: 103%;
  z-index: 80;
}

.info_block_slider .container .block .block_sld .slick-dots li {
  width: 8px;
  height: 8px;
  border: 2px solid #444648;
  border-radius: 50%;
  border-bottom-right-radius: 90%;
  border-top-right-radius: 40%;
  border-bottom-right-radius: 10%;
  border-top-left-radius: 60%;
  transition: all ease-out 0.3s;
}

.info_block_slider .container .block .block_sld .slick-dots li:hover {
  background: #fff;
}

.info_block_slider .container .block .block_sld .slick-dots button {
  opacity: 0;
  visibility: hidden;
}

.info_block_slider .container .block .block_sld .slick-dotted.slick-slider {
  margin-bottom: 0;
}

.info_block_slider .container .block .block_sld .slick-dots li.slick-active {
  box-shadow: 0 0 0 0px rgba(255, 222, 187, 0.8);
  border-color: #e5017c;
  transform: scale(1.3);
}

.info_block_slider .container .block .read_news {
  text-align: center;
  width: 100%;
  margin-top: 70px;
}

.info_block_slider .container .block .read_news .button {
  text-decoration: none;
  background: #e5017c;
  transition: all 0.2s ease-in-out;
  color: #fff;
  padding: 8px 12px 9px 12px;
  border-radius: 5px;
}

.background_certificates {
  background: url(../img/947107_great-flamingo.jpg) no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  height: 250px;
}

.info_certificates {
  padding-bottom: 50px;
}

.info_certificates .caption {
  padding-top: 50px;
  padding-bottom: 60px;
}

.info_certificates .caption h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  font-size: 25px;
  line-height: 26px;
  font-weight: 700;
  color: #333;
  text-align: center;
}

.info_certificates .caption h2 span {
  color: #e5017c;
}

.info_certificates .container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  justify-content: center;
}

.info_certificates .container .block {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(100% - 30px);
}

.info_certificates .container .block .block_certifi a {
  margin: 0 10px;
  border: 5px solid #ececec;
}

.info_certificates .container .block .block_certifi .prev {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  content: "";
  top: 45%;
  left: -5%;
  background: #FFF7EB;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  z-index: 80;
  box-shadow: 0 0 10px rgba(229, 1, 124, 0.6);
  cursor: pointer;
}

.info_certificates .container .block .block_certifi .prev .fa {
  font-size: 15px;
  color: #e5017c;
}

.info_certificates .container .block .block_certifi .next {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  content: "";
  top: 45%;
  right: -5%;
  background: #FFF7EB;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  z-index: 80;
  box-shadow: 0 0 10px rgba(229, 1, 124, 0.6);
  cursor: pointer;
}

.info_certificates .container .block .block_certifi .next .fa {
  font-size: 15px;
  color: #e5017c;
}

.info_certificates .container .block .block_certifi .slick-dots {
  top: 103%;
  z-index: 80;
}

.info_certificates .container .block .block_certifi .slick-dots li {
  width: 8px;
  height: 8px;
  border: 2px solid #444648;
  border-radius: 50%;
  border-bottom-right-radius: 90%;
  border-top-right-radius: 40%;
  border-bottom-right-radius: 10%;
  border-top-left-radius: 60%;
  transition: all ease-out 0.3s;
}

.info_certificates .container .block .block_certifi .slick-dots li:hover {
  background: #fff;
}

.info_certificates .container .block .block_certifi .slick-dots button {
  opacity: 0;
  visibility: hidden;
}

.info_certificates .container .block .block_certifi .slick-dotted.slick-slider {
  margin-bottom: 0;
}

.info_certificates .container .block .block_certifi .slick-dots li.slick-active {
  box-shadow: 0 0 0 0px rgba(255, 222, 187, 0.8);
  border-color: #e5017c;
  transform: scale(1.3);
}

.background_partners {
  background: url(../img/ice-594591_1920.jpg) no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  height: 250px;
}

.info_partners {
  padding-bottom: 150px;
  position: relative;
}

.info_partners:before {
  position: absolute;
  content: "";
  bottom: -2px;
  left: 5%;
  width: 150px;
  height: 150px;
  background: url(../img/footer_1.png) no-repeat;
}

.info_partners:after {
  position: absolute;
  content: "";
  bottom: -5px;
  right: 5%;
  width: 150px;
  height: 150px;
  background: url(../img/footer_2.png) no-repeat;
}

.info_partners .caption {
  padding-top: 50px;
  padding-bottom: 60px;
}

.info_partners .caption h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  font-size: 25px;
  line-height: 26px;
  font-weight: 700;
  color: #333;
  text-align: center;
}

.info_partners .caption h2 span {
  color: #e5017c;
}

.info_partners .container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  justify-content: center;
}

.info_partners .container .block {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(41.66666666666667% - 30px);
}

.info_partners .container .block .text {
  border: 1px solid #ECECEC;
  text-align: center;
  padding-bottom: 10px;
  box-shadow: 0px 5px 10px 0px rgba(30, 37, 54, 0.08);
}

.info_partners .container .block .text h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ECECEC;
  background: #ECECEC;
}

.info_partners .container .block .text .tel {
  display: block;
  padding: 5px 0;
  margin: 5px 0;
  text-decoration: none;
  color: #333;
  font-size: 16px;
}

.info_partners .container .block .text p {
  display: block;
  padding: 5px 0 10px 0;
  color: #333;
  font-size: 16px;
  border-top: 1px solid #ECECEC;
  border-bottom: 1px solid #ECECEC;
}

.info_partners .container .block .text p .partners {
  text-decoration: none;
  color: #333;
}

.info_partners .container .block .text p .partners span {
  color: #e5017c;
}

.info_partners .container .block .text .bell {
  margin-top: 9px;
  display: inline-block;
  text-decoration: none;
  border: none;
  background: #e5017c;
  color: #fff;
  padding: 5px 13px 6px 13px;
  border-radius: 5px;
  outline: none;
}

.info_partners .container .block .pdf_form {
  display: flex;
  align-content: center;
  justify-content: center;
}

.info_partners .container .block .pdf_form a {
  text-decoration: none;
  background: #e5017c;
  padding: 5px 13px 6px 13px;
  border-radius: 5px;
  color: #fff;
  margin: 25px 0 0 15px;
}

.info_partners .container .block_partners {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(100% - 30px);
  padding-top: 50px;
  padding-bottom: 10px;
}

.info_partners .container .block_partners .slider {
  display: flex;
  justify-content: center;
}

.info_partners .container .block_partners .prev {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  content: "";
  top: 53%;
  left: -5%;
  background: #FFF7EB;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  z-index: 80;
  box-shadow: 0 0 10px rgba(229, 1, 124, 0.6);
  cursor: pointer;
}

.info_partners .container .block_partners .prev .fa {
  font-size: 15px;
  color: #e5017c;
}

.info_partners .container .block_partners .next {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  content: "";
  top: 53%;
  right: -5%;
  background: #FFF7EB;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  z-index: 80;
  box-shadow: 0 0 10px rgba(229, 1, 124, 0.6);
  cursor: pointer;
}

.info_partners .container .block_partners .next .fa {
  font-size: 15px;
  color: #e5017c;
}

.info_partners .container .block_partners .slick-dots {
  top: 103%;
  z-index: 1010;
}

.info_partners .container .block_partners .slick-dots li {
  width: 8px;
  height: 8px;
  border: 2px solid #444648;
  border-radius: 50%;
  border-bottom-right-radius: 90%;
  border-top-right-radius: 40%;
  border-bottom-right-radius: 10%;
  border-top-left-radius: 60%;
  transition: all ease-out 0.3s;
}

.info_partners .container .block_partners .slick-dots li:hover {
  background: #fff;
}

.info_partners .container .block_partners .slick-dots button {
  opacity: 0;
  visibility: hidden;
}

.info_partners .container .block_partners .slick-dotted.slick-slider {
  margin-bottom: 0;
}

.info_partners .container .block_partners .slick-dots li.slick-active {
  box-shadow: 0 0 0 0px rgba(255, 222, 187, 0.8);
  border-color: #e5017c;
  transform: scale(1.3);
}

.info_footer {
  padding-top: 30px;
  position: relative;
  background: url(../img/footer_bg.gif) #171f22 no-repeat;
  background-position: bottom right;
}

.info_footer .container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  justify-content: center;
}

.info_footer .container .block_logo {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(16.666666666666668% - 30px);
  padding-top: 50px;
}

.info_footer .container .block {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(25% - 30px);
}

.info_footer .container .block h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  color: #fff;
  border-bottom: 1px solid #2B2B2B;
  padding-bottom: 7px;
  font-size: 18px;
}

.info_footer .container .block ul {
  padding: 0;
  margin: 0;
  padding-top: 10px;
}

.info_footer .container .block ul li {
  padding: 0;
  margin: 0;
  list-style: none;
  border-bottom: 1px solid #2B2B2B;
  padding-top: 4px;
}

.info_footer .container .block ul li span {
  color: #fff;
  opacity: 0.5;
}

.info_footer .container .block ul li a {
  text-decoration: none;
  font-size: 15px;
  padding-top: 4px;
  color: #fff;
  opacity: .5;
  transition: all 0.2s ease-in-out;
}

.info_footer .container .block ul li a:hover {
  opacity: 1;
  text-decoration: underline;
}

.info_footer .container .block ul li .footer-viber {
  opacity: 1;
}

.info_footer .container .block ul li .footer-viber img {
  width: 40px;
  height: 40px;
}

.info_footer .container .block ul li .footer-skype {
  opacity: 1;
}

.info_footer .container .block ul li .footer-skype img {
  width: 37px;
  height: 37px;
}

.info_footer .container .block ul li .footer-telegram {
  opacity: 1;
}

.info_footer .container .block ul li .footer-telegram img {
  width: 37px;
  height: 37px;
}

.info_footer .container .block ul li:last-child {
  border-bottom: none;
}

.info_footer .container .block .social_network {
  display: flex;
  padding-top: 10px;
}

.info_footer .container .block .social_network a {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(top, #C7006B, #AD005E);
  box-shadow: 3px 3px 7px 0 rgba(229, 1, 124, 0.4), inset 0 -1px 0 0 #e5017c;
  border-bottom: 1px solid #e5017c;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  width: 33px;
  height: 33px;
  line-height: 0;
  border-radius: 50%;
  text-decoration: none;
  margin: 0 5px;
}

.info_footer .container .block .social_network a .fa {
  color: #fff;
  line-height: 0;
  font-size: 16px;
}

.info_footer .container .block .liveinternet {
  display: flex;
  padding-top: 15px;
}

.info_footer .container .block .liveinternet a {
  padding: 5px 3px;
}

.info_footer .container .block:nth-child(3) {
  width: calc(25% - 30px);
}

.info_footer .container .block:nth-child(3) ul li {
  padding-bottom: 7px;
}

.info_footer .container .block:nth-child(4) {
  width: calc(25% - 30px);
}

.info_footer .container .block:nth-child(4) p {
  color: #fff;
  opacity: .5;
  padding-top: 10px;
  padding-bottom: 10px;
}

.info_footer .author {
  text-align: center;
  padding: 40px 0px 5px 0px;
  position: relative;
}

.info_footer .author:before {
  display: block;
  background: #2B2B2B;
  content: '';
  width: 20%;
  position: absolute;
  left: 5%;
  top: 49px;
  height: 1px;
  z-index: 1;
}

.info_footer .author:after {
  display: block;
  background: #2B2B2B;
  content: '';
  width: 20%;
  position: absolute;
  right: 5%;
  top: 49px;
  height: 1px;
  z-index: 1;
}

.info_footer .author .official {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 5px;
}

.info_footer .author .official a {
  text-decoration: none;
  color: #e5017c;
  padding-left: 5px;
}

.info_footer .author h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  color: #adb2b7;
  font-size: 13px;
  letter-spacing: 1px;
}

.info_footer .author h2 span {
  color: #fff;
}

.info_footer .author p {
  color: #adb2b7;
  font-size: 13px;
}

.info_footer .buttons {
  display: none;
  cursor: pointer;
}

.info_footer .buttons .fa:before {
  position: absolute;
  right: 0;
  bottom: 0;
  margin-bottom: 150px;
  color: #fff;
  padding: 10px 21px;
  padding-left: 15px;
  margin-right: -5px;
  font-size: 18px;
  position: fixed;
  z-index: 1005;
  cursor: pointer;
  background: rgba(51, 51, 51, 0.6);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.9);
  transition: background, 0.3s, color, 0.3s;
}

.info_footer .buttons:hover .fa:before {
  background: rgba(51, 51, 51, 0.9);
  color: #e5017c;
  transform: rotateX(20deg);
}

.contacts_page {
  position: static;
}

.contact-section {
  padding-bottom: 0;
}

.contact-section .map_yan {
  height: 500px;
}

.contact-section .map_yan #yandex-map-cart {
  height: 100%;
}

.contact-section .yandex-map-cart img {
  display: none;
}

.contact-section .yandex-map-cart .caption {
  text-align: center;
}

.contact-section .yandex-map-cart .caption h3 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333333;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  padding: 10px 0 10px 0;
}

.contact-section .yandex-map-cart .caption p {
  width: 50%;
  margin: 0 auto;
}

.contact-section .yandex-map-cart .info {
  display: none;
}

.contact-section .printing {
  display: block;
  margin: 0 auto;
  background: #fff;
  border: none;
  cursor: pointer;
  font-weight: 700;
  border: 2px solid #e5017c;
  border-radius: 30px;
  padding: 5px 10px;
  margin-top: 5px;
  transition: all 0.2s ease-in-out;
}

.contact-section .printing:hover {
  background: #e5017c;
  color: #fff;
}

.contact-section h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  color: #333333;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 25px;
}

.contact-section .contact-info {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  justify-content: center;
}

.contact-section .contact-info .info {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(33.333333333333336% - 30px);
  margin-top: 50px;
}

.contact-section .contact-info .info ul.information-list {
  padding: 0;
  margin: 0;
  overflow: hidden;
  margin-top: 45px;
}

.contact-section .contact-info .info ul.information-list li {
  padding: 0;
  margin: 0;
  list-style: none;
  border-bottom: 1px solid #dddddd;
  margin-bottom: 15px;
}

.contact-section .contact-info .info ul.information-list li i {
  float: left;
  display: inline-block;
  color: #333333;
  font-size: 13px;
  line-height: 24px;
  margin-left: 3px;
}

.contact-section .contact-info .info ul.information-list li span {
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 8px;
  margin-left: 25px;
  color: #333333;
}

.contact-section .contact-info .info ul.information-list li a {
  text-decoration: none;
  font-size: 14px;
  color: #333333;
  font-weight: 400;
  line-height: 20px;
  margin-left: 10px;
  margin-bottom: 10px;
}

.contact-section .contact-info .info ul.information-list li:last-child {
  border-bottom: none;
}

.contact-section .contact-info .form {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(66.66666666666667% - 30px);
  margin-top: 50px;
  margin-bottom: 20px;
}

.contact-section .contact-info .form h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  color: #222;
  font-size: 20px;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1.5px;
  font-weight: 700;
  padding-bottom: 20px;
}

.contact-section .contact-info .form #feedback-form input[type="text"],
.contact-section .contact-info .form #feedback-form [type="email"],
.contact-section .contact-info .form #feedback-form [type="tel"],
.contact-section .contact-info .form #feedback-form textarea {
  width: 100%;
  padding: 10px 20px;
  border: 1px solid #dedede;
  margin-bottom: 20px;
  outline: none;
  transition: all 0.2s ease-in-out;
  color: #666666;
  font-size: 13px;
  box-sizing: border-box;
  resize: vertical;
}

.contact-section .contact-info .form #feedback-form .input {
  display: flex;
}

.contact-section .contact-info .form #feedback-form .input input:nth-child(2) {
  margin-left: 15px;
  margin-right: 15px;
}

.contact-section .contact-info .form #feedback-form input[type="text"]:focus,
.contact-section .contact-info .form #feedback-form textarea:focus {
  border: 1px solid #e5017c;
}

.contact-section .contact-info .form #feedback-form input[type="email"]:focus {
  border: 1px solid #e5017c;
}

.contact-section .contact-info .form #feedback-form textarea {
  height: 146px;
}

.contact-section .contact-info .form #feedback-form input[type="submit"] {
  padding: 12px 30px;
  outline: none;
  border: none;
  background: #e5017c;
  color: #fff;
  font-size: 13px;
  margin-bottom: 10px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.contact-section .contact-info .form #feedback-form input[type="submit"]:hover {
  background: #666666;
}

.contact-section .contact-info .form #feedback-form input[type="checkbox"] {
  margin-left: 15px;
  margin-right: 5px;
  cursor: pointer;
}

.contact-section .contact-info .form #feedback-form span a {
  color: #333;
  transition: all 0.2s ease-in-out;
  outline: none;
}

.contact-section .contact-info .form #feedback-form span a:hover {
  color: #e5017c;
}

.info_slider_section {
  padding-top: 140px;
}

.info_slider_section .container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.info_slider_section .container .block {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(100% - 30px);
}

.info_slider_section .container .block .slider img {
  width: 100%;
}

.info_slider_section .container .prev {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  content: "";
  top: 45%;
  left: -5%;
  background: #FFF7EB;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  z-index: 80;
  box-shadow: 0 0 10px rgba(229, 1, 124, 0.6);
  cursor: pointer;
}

.info_slider_section .container .prev .fa {
  font-size: 15px;
  color: #e5017c;
}

.info_slider_section .container .next {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  content: "";
  top: 45%;
  right: -5%;
  background: #FFF7EB;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  z-index: 80;
  box-shadow: 0 0 10px rgba(229, 1, 124, 0.6);
  cursor: pointer;
}

.info_slider_section .container .next .fa {
  font-size: 15px;
  color: #e5017c;
}

.info_slider_section .container .slick-dots {
  top: 93%;
  z-index: 80;
}

.info_slider_section .container .slick-dots li {
  width: 8px;
  height: 8px;
  border: 2px solid #444648;
  border-radius: 50%;
  border-bottom-right-radius: 90%;
  border-top-right-radius: 40%;
  border-bottom-right-radius: 10%;
  border-top-left-radius: 60%;
  transition: all ease-out 0.3s;
}

.info_slider_section .container .slick-dots li:hover {
  background: #fff;
}

.info_slider_section .container .slick-dots button {
  opacity: 0;
  visibility: hidden;
}

.info_slider_section .container .slick-dotted.slick-slider {
  margin-bottom: 0;
}

.info_slider_section .container .slick-dots li.slick-active {
  box-shadow: 0 0 0 0px rgba(255, 222, 187, 0.8);
  border-color: #e5017c;
  transform: scale(1.3);
}

.info_production {
  padding-bottom: 80px;
}

.info_production .container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  padding-top: 10px;
}

.info_production .container .block_cat {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(25% - 30px);
}

.info_production .container .block_cat p {
  background: #e5017c;
  border-bottom: 1px solid #e5017c;
  padding: 0 0 0 10px;
  line-height: 37px;
  width: 60%;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  font-size: 17px;
  position: relative;
}

.info_production .container .block_cat ul.nav {
  padding: 0;
  margin: 0;
}

.info_production .container .block_cat ul.nav .drop {
  padding: 0;
  margin: 0;
  list-style: none;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 2px 5px 0px rgba(50, 50, 50, 0.2);
}

.info_production .container .block_cat ul.nav .drop .capt {
  padding: 15px 10px;
  background: linear-gradient(#F5F5F5, #F5F5F5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-size: 14px;
  text-transform: uppercase;
  border-left: 2px solid #e5017c;
}

.info_production .container .block_cat ul.nav .drop .dropdown {
  padding: 0;
  margin: 0;
  margin: 15px 30px;
}

.info_production .container .block_cat ul.nav .drop .dropdown li {
  padding: 0;
  margin: 0;
  list-style: none;
  list-style-type: disc;
  color: #e5017c;
  padding: 5px 0;
}

.info_production .container .block_cat ul.nav .drop .dropdown li a {
  text-decoration: none;
  color: #e5017c;
  font-size: 15px;
}

.info_production .container .block_cat_mob {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  display: none;
  margin-bottom: 25px;
}

.info_production .container .block_cat_mob p {
  background: #e5017c;
  border-bottom: 1px solid #e5017c;
  line-height: 38px;
  width: 100%;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  font-size: 17px;
  text-align: center;
}

.info_production .container .block_cat_mob p .fa {
  padding-left: 5px;
}

.info_production .container .block_cat_mob ul.nav {
  padding: 0;
  margin: 0;
}

.info_production .container .block_cat_mob ul.nav .drop {
  padding: 0;
  margin: 0;
  list-style: none;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 2px 5px 0px rgba(50, 50, 50, 0.2);
}

.info_production .container .block_cat_mob ul.nav .drop .capt {
  padding: 10px 10px;
  background: linear-gradient(#F5F5F5, #F5F5F5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-size: 12px;
  text-transform: uppercase;
  border-left: 2px solid #e5017c;
}

.info_production .container .block_cat_mob ul.nav .drop .dropdown {
  padding: 0;
  margin: 0;
  display: none;
  margin: 15px 30px;
}

.info_production .container .block_cat_mob ul.nav .drop .dropdown li {
  padding: 0;
  margin: 0;
  list-style: none;
  list-style-type: disc;
  color: #e5017c;
  padding: 5px 0;
}

.info_production .container .block_cat_mob ul.nav .drop .dropdown li a {
  text-decoration: none;
  color: #e5017c;
  font-size: 15px;
}

.info_production .container .list_water {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(75% - 30px);
}

.info_production .container .list_water .caption {
  position: relative;
}

.info_production .container .list_water .caption h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  font-size: 26px;
  font-weight: 700;
  line-height: 24px;
  color: #333;
  text-align: center;
  padding-bottom: 30px;
}

.info_production .container .list_water .caption:before {
  position: absolute;
  content: "";
  background: #333;
  bottom: 35%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 60px;
  height: 2px;
}

.info_production .container .list_water .description p {
  padding-bottom: 15px;
}

.info_production .container .list_water .description p span {
  font-weight: 700;
}

.info_production .container .list_water .water {
  display: flex;
  flex-wrap: wrap;
  padding-top: 30px;
}

.info_production .container .list_water .water .block {
  width: 28%;
  border: 1px solid #e5e5e5;
  box-shadow: 0 0 5px #e5e5e5;
  transition: all .4s;
  text-align: center;
  padding: 10px 15px 10px 15px;
  margin: 0 5px 30px 5px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.info_production .container .list_water .water .block .under_order {
  position: absolute;
  content: "";
  top: 18px;
  right: -40px;
  background-color: #e90d8b;
  font-size: 15px;
  font-weight: 300;
  text-align: center;
  transform: rotate(45deg);
  padding: 6px 0;
  width: 150px;
  box-shadow: 0 0 5px 1px rgba(100, 100, 100, 0.3);
  color: #fff;
}

.info_production .container .list_water .water .block .photo {
  overflow: hidden;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 7px;
  position: relative;
}

.info_production .container .list_water .water .block .photo .img {
  outline: none;
}

.info_production .container .list_water .water .block .photo .img img {
  width: 100px;
  height: auto;
}

.info_production .container .list_water .water .block .product_name {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.info_production .container .list_water .water .block .product_name a {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  outline: none;
  color: #333;
}

.info_production .container .list_water .water .block .product_name a strong {
  font-weight: 700;
}

.info_production .container .list_water .water .block .product_name a p:nth-child(1) {
  font-size: 18px;
  color: #333;
  font-weight: 700;
  text-transform: uppercase;
}

.info_production .container .list_water .water .block .product_name a p:nth-child(2) {
  padding-top: 3px;
  font-size: 18px;
  color: #333;
  font-weight: 700;
}

.info_production .container .list_water .water .block .product_name a p:nth-child(4) {
  font-weight: 700;
}

.info_production .container .list_water .water .block .product_name a p:nth-child(5) {
  font-weight: 700;
}

.info_production .container .list_water .water .block .price .product_bottle {
  display: flex;
  justify-content: center;
  padding-bottom: 5px;
  font-size: 16px;
  font-weight: 400;
}

.info_production .container .list_water .water .block .price .product_bottle p {
  padding-left: 3px;
  color: #e5017c;
}

.info_production .container .list_water .water .block .price .product_packaging {
  display: flex;
  justify-content: center;
  font-size: 16px;
  padding-bottom: 5px;
  font-weight: 400;
}

.info_production .container .list_water .water .block .price .product_packaging p {
  padding-left: 3px;
  color: #e5017c;
}

.info_production .container .list_water .water .block .text {
  padding-top: 5px;
  margin-top: auto;
}

.info_production .container .list_water .water .block .text .product_btn {
  display: inline-block;
  margin: 6px 0;
}

.info_production .container .list_water .water .block .text .product_btn button {
  border-radius: 5px;
  text-decoration: none;
  color: #fff;
  outline: none;
  border: none;
  background: #e5017c;
  cursor: pointer;
  padding: 8px 25px 9px 25px;
  transition: all .2s;
  font-size: 14px;
}

.info_production .container .list_water .water .block .text .product_btn button .fa {
  color: #fff;
  padding-right: 5px;
  font-size: 16px;
}

.info_production .container .list_water .water .block .text .product_btn button:hover {
  box-shadow: 0 0 5px #e5017c;
}

.info_production .container .list_water .water .block .text p {
  font-size: 13px;
}

.info_production .container .list_water .water .block:hover {
  border: 1px solid #e5017c;
  box-shadow: 0 0 5px #e5017c;
}

.info_production .container .basket_open {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(75% - 30px);
}

.info_production .container .basket_open .caption {
  padding-bottom: 20px;
}

.info_production .container .basket_open .caption h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  color: #e5017c;
}

.info_production .container .basket_open form {
  border: 1px solid #e5e5e5;
}

.info_production .container .basket_open form thead:first-child {
  background: linear-gradient(#F5F5F5, #F5F5F5);
}

.info_production .container .basket_open form thead tr {
  text-align: center;
}

.info_production .container .basket_open form thead tr th {
  border: 1px solid #e5e5e5;
  border-right: none;
  border-left: none;
  padding: 10px 5px;
  text-align: center;
}

.info_production .container .basket_open form thead tr th p {
  font-weight: 700;
}

.info_production .container .basket_open form thead tr th a {
  text-decoration: none;
  color: #333;
}

.info_production .container .basket_open form thead tr th .fa {
  color: #e5017c;
}

.info_production .container .basket_open form thead .product-remove {
  width: 5%;
}

.info_production .container .basket_open form thead .product-thumbnail {
  width: 10%;
}

.info_production .container .basket_open form thead .product-name a {
  text-align: center;
  width: 370px;
  display: block;
  word-wrap: break-word;
}

.info_production .container .basket_open form thead .product-price {
  width: 10%;
}

.info_production .container .basket_open form thead .product-quantity {
  width: 25%;
}

.info_production .container .basket_open form thead .product-quantity .number {
  text-align: right;
}

.info_production .container .basket_open form thead .product-quantity .number .packing {
  padding-right: 5px;
}

.info_production .container .basket_open form thead .product-quantity .number .minus {
  width: 10px;
  height: 10px;
  background: #f2f2f2;
  border-radius: 4px;
  padding: 3px 5px 3px 5px;
  border: 1px solid #ddd;
  cursor: pointer;
}

.info_production .container .basket_open form thead .product-quantity .number input {
  border: 1px solid #ddd;
  text-align: center;
  height: 22px;
  margin: 0 5px;
}

.info_production .container .basket_open form thead .product-quantity .number .plus {
  width: 10px;
  height: 10px;
  background: #f2f2f2;
  border-radius: 4px;
  padding: 3px 5px 3px 5px;
  border: 1px solid #ddd;
  cursor: pointer;
}

.info_production .container .basket_open form thead .product-subtotal {
  width: 10%;
}

.info_production .container .basket_open form .totals {
  display: flex;
  justify-content: flex-end;
  padding: 10px 15px;
  font-weight: 700;
}

.info_production .container .basket_open form .totals p {
  padding-right: 5px;
}

.info_production .container .basket_open form .note {
  text-align: center;
  padding: 15px 50px 0 50px;
}

.info_production .container .basket_open form .note h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  color: #e5017c;
  padding-bottom: 5px;
}

.info_production .container .basket_open form .note p {
  font-size: 16px;
  text-align: center;
  color: #e5017c;
}

.info_production .container .basket_open form .form_goods {
  padding: 20px 20px 20px 20px;
}

.info_production .container .basket_open form .form_goods input[type="text"],
.info_production .container .basket_open form .form_goods [type="email"],
.info_production .container .basket_open form .form_goods [type="tel"],
.info_production .container .basket_open form .form_goods textarea {
  width: 100%;
  padding: 10px 20px;
  border: 1px solid #dedede;
  margin-bottom: 20px;
  outline: none;
  transition: all 0.2s ease-in-out;
  color: #666666;
  font-size: 13px;
  box-sizing: border-box;
  resize: vertical;
}

.info_production .container .basket_open form .form_goods .input input:nth-child(1) {
  margin-right: 15px;
}

.info_production .container .basket_open form .form_goods .input .block .select {
  position: relative;
  margin-bottom: 10px;
}

.info_production .container .basket_open form .form_goods .input .block .select select {
  width: 180px;
  height: 38px;
  border: 1px solid #dedede;
  color: #666666;
  font-size: 13px;
  cursor: pointer;
}

.info_production .container .basket_open form .form_goods .input .block .select p {
  position: absolute;
  content: "";
  bottom: -15px;
  left: 0;
}

.info_production .container .basket_open form .form_goods .input .block .cities {
  display: none;
  margin-left: 15px;
}

.info_production .container .basket_open form .form_goods .input .block .shipping_cost {
  display: none;
  padding-left: 5px;
  padding-top: 5px;
  color: #e5017c;
}

.info_production .container .basket_open form .form_goods .input .block .free_shipping {
  display: none;
  padding-left: 5px;
  padding-top: 5px;
  color: #e5017c;
}

.info_production .container .basket_open form .form_goods .input .block .shipping_specify {
  display: none;
  padding-left: 5px;
  padding-top: 5px;
  color: #e5017c;
}

.info_production .container .basket_open form .form_goods .input .block .active_floor_show {
  display: flex;
}

.info_production .container .basket_open form .form_goods .input .block .active_floor_hide {
  display: none;
}

.info_production .container .basket_open form .form_goods .input .block:nth-child(1) {
  display: flex;
  width: 100%;
}

.info_production .container .basket_open form .form_goods .input .block:nth-child(1) input:nth-child(2) {
  margin-right: 15px;
}

.info_production .container .basket_open form .form_goods .input .block:nth-child(2) {
  width: 100%;
  display: flex;
}

.info_production .container .basket_open form .form_goods .input .block:nth-child(2) .city {
  display: block;
  margin-left: 15px;
}

.info_production .container .basket_open form .form_goods .input .block:nth-child(2) .select {
  width: 50%;
}

.info_production .container .basket_open form .form_goods .input .block:nth-child(2) input {
  width: 56%;
  margin-left: 15px;
}

.info_production .container .basket_open form .form_goods input[type="text"]:focus,
.info_production .container .basket_open form .form_goods textarea:focus {
  border: 1px solid #e5017c;
}

.info_production .container .basket_open form .form_goods input[type="email"]:focus {
  border: 1px solid #e5017c;
}

.info_production .container .basket_open form .form_goods input[type="tel"]:focus {
  border: 1px solid #e5017c;
}

.info_production .container .basket_open form .form_goods textarea {
  height: 146px;
  width: 100%;
  margin-top: 10px;
}

.info_production .container .basket_open form .form_goods input[type="submit"] {
  padding: 12px 30px;
  outline: none;
  border: none;
  background: #e5017c;
  color: #fff;
  font-size: 13px;
  margin-bottom: 10px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.info_production .container .basket_open form .form_goods input[type="submit"]:hover {
  background: #666666;
}

.info_production .container .basket_open form .form_goods input[type="checkbox"] {
  margin-left: 15px;
  margin-right: 5px;
  cursor: pointer;
}

.info_production .container .basket_open form .form_goods span a {
  color: #333;
  transition: all 0.2s ease-in-out;
}

.info_production .container .basket_open form .form_goods span a:hover {
  color: #e5017c;
}

.info_internal_product_page {
  padding-top: 20px;
  padding-bottom: 80px;
}

.info_internal_product_page .container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.info_internal_product_page .container .block_cat {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(25% - 30px);
}

.info_internal_product_page .container .block_cat p {
  background: #e5017c;
  border-bottom: 1px solid #e5017c;
  padding: 0 0 0 10px;
  line-height: 38px;
  width: 55%;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  font-size: 17px;
}

.info_internal_product_page .container .block_cat ul.nav {
  padding: 0;
  margin: 0;
}

.info_internal_product_page .container .block_cat ul.nav .drop {
  padding: 0;
  margin: 0;
  list-style: none;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 2px 5px 0px rgba(50, 50, 50, 0.2);
}

.info_internal_product_page .container .block_cat ul.nav .drop .capt {
  padding: 15px 10px;
  background: linear-gradient(#F5F5F5, #F5F5F5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-size: 14px;
  text-transform: uppercase;
  border-left: 2px solid #e5017c;
}

.info_internal_product_page .container .block_cat ul.nav .drop .dropdown {
  padding: 0;
  margin: 0;
  margin: 15px 30px;
}

.info_internal_product_page .container .block_cat ul.nav .drop .dropdown li {
  padding: 0;
  margin: 0;
  list-style: none;
  list-style-type: disc;
  color: #e5017c;
  padding: 5px 0;
}

.info_internal_product_page .container .block_cat ul.nav .drop .dropdown li a {
  text-decoration: none;
  color: #e5017c;
  font-size: 15px;
}

.info_internal_product_page .container .block_cat_mob {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  display: none;
  margin-bottom: 25px;
}

.info_internal_product_page .container .block_cat_mob p {
  background: #e5017c;
  border-bottom: 1px solid #e5017c;
  line-height: 38px;
  width: 100%;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  font-size: 17px;
  text-align: center;
}

.info_internal_product_page .container .block_cat_mob p .fa {
  padding-left: 5px;
}

.info_internal_product_page .container .block_cat_mob ul.nav {
  padding: 0;
  margin: 0;
}

.info_internal_product_page .container .block_cat_mob ul.nav .drop {
  padding: 0;
  margin: 0;
  list-style: none;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 2px 5px 0px rgba(50, 50, 50, 0.2);
}

.info_internal_product_page .container .block_cat_mob ul.nav .drop .capt {
  padding: 10px 10px;
  background: linear-gradient(#F5F5F5, #F5F5F5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-size: 12px;
  text-transform: uppercase;
  border-left: 2px solid #e5017c;
}

.info_internal_product_page .container .block_cat_mob ul.nav .drop .dropdown {
  padding: 0;
  margin: 0;
  display: none;
  margin: 15px 30px;
}

.info_internal_product_page .container .block_cat_mob ul.nav .drop .dropdown li {
  padding: 0;
  margin: 0;
  list-style: none;
  list-style-type: disc;
  color: #e5017c;
  padding: 5px 0;
}

.info_internal_product_page .container .block_cat_mob ul.nav .drop .dropdown li a {
  text-decoration: none;
  color: #e5017c;
  font-size: 15px;
}

.info_internal_product_page .container .picture_goods {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(33.333333333333336% - 30px);
}

.info_internal_product_page .container .picture_goods .caption {
  padding: 10px 0 25px 0;
  display: none;
}

.info_internal_product_page .container .picture_goods .caption p:nth-child(1) {
  font-size: 23px;
  color: #333;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
}

.info_internal_product_page .container .picture_goods .caption p:nth-child(2) {
  font-size: 23px;
  color: #333;
  line-height: 24px;
}

.info_internal_product_page .container .picture_goods .caption p:nth-child(3) {
  font-size: 16px;
  line-height: 20px;
}

.info_internal_product_page .container .picture_goods .caption p:nth-child(4) {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

.info_internal_product_page .container .picture_goods .caption p:nth-child(5) {
  font-size: 16px;
  line-height: 20px;
}

.info_internal_product_page .container .picture_goods .img {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 10px;
}

.info_internal_product_page .container .picture_goods .img a {
  width: 100%;
}

.info_internal_product_page .container .picture_goods .img a img {
  max-width: 100%;
}

.info_internal_product_page .container .product_description {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(41.66666666666667% - 30px);
}

.info_internal_product_page .container .product_description .caption p:nth-child(1) {
  font-size: 23px;
  color: #333;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
}

.info_internal_product_page .container .product_description .caption p:nth-child(2) {
  font-size: 23px;
  color: #333;
  line-height: 24px;
}

.info_internal_product_page .container .product_description .caption p:nth-child(3) {
  font-size: 16px;
  line-height: 20px;
}

.info_internal_product_page .container .product_description .caption p:nth-child(4) {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

.info_internal_product_page .container .product_description .caption p:nth-child(5) {
  font-size: 16px;
  line-height: 20px;
}

.info_internal_product_page .container .product_description .block_price {
  padding: 15px 0 10px 0;
  display: flex;
}

.info_internal_product_page .container .product_description .block_price h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  display: block;
  font-size: 17px;
  padding-bottom: 5px;
  font-weight: 700;
  color: #e5017c;
}

.info_internal_product_page .container .product_description .block_price h3 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  font-size: 15px;
  padding-left: 5px;
}

.info_internal_product_page .container .product_description .text {
  padding-bottom: 15px;
}

.info_internal_product_page .container .product_description .enter_area {
  padding: 15px 10px;
  border: 1px solid #e5e5e5;
}

.info_internal_product_page .container .product_description .enter_area .block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.info_internal_product_page .container .product_description .enter_area .block .area .price span:nth-child(1) {
  font-size: 20px;
  color: #333;
}

.info_internal_product_page .container .product_description .enter_area .block .area .price span:nth-child(2) {
  font-size: 20px;
  color: #e5017c;
}

.info_internal_product_page .container .product_description .enter_area .block .area .price span:nth-child(3) {
  font-size: 20px;
  color: #333;
}

.info_internal_product_page .container .product_description .enter_area .block .area .price:nth-child(1) {
  padding-bottom: 7px;
}

.info_internal_product_page .container .product_description .enter_area .block .basket {
  margin-right: 20px;
}

.info_internal_product_page .container .product_description .enter_area .block .basket button {
  background: #e5017c;
  font-size: 17px;
  border-radius: 5px;
  outline: none;
  color: #fff;
  border: none;
  padding: 7px 10px;
  padding-top: 5px;
  cursor: pointer;
}

.info_internal_product_page .container .product_description .request_call {
  margin-top: 20px;
  background: linear-gradient(#F5F5F5, #F5F5F5);
  padding: 10px;
}

.info_internal_product_page .container .product_description .request_call h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  padding-bottom: 5px;
}

.info_internal_product_page .characteristics {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  padding-bottom: 20px;
  justify-content: flex-end;
}

.info_internal_product_page .characteristics .tab-posts-box {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(75% - 30px);
  margin-top: 25px;
}

.info_internal_product_page .characteristics .tab-posts-box ul.nav {
  padding: 0;
  margin: 0;
  display: flex;
}

.info_internal_product_page .characteristics .tab-posts-box ul.nav li {
  padding: 0;
  margin: 0;
  list-style: none;
  margin-right: 7px;
}

.info_internal_product_page .characteristics .tab-posts-box ul.nav li a {
  display: flex;
  border: none;
  text-decoration: none;
  transition: all 0.2 ease-in-out;
  padding: 7px 25px;
  background: transparent;
  color: #333;
  font-size: 13px;
  font-weight: 700;
  margin: 0;
  border: 1px solid #e5e5e5;
  box-shadow: 0 0 4px rgba(123, 152, 165, 0.2);
  font-size: 14px;
  outline: none;
  background: linear-gradient(#F5F5F5, #F5F5F5);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.info_internal_product_page .characteristics .tab-posts-box ul.nav li a:hover {
  color: #000;
}

.info_internal_product_page .characteristics .tab-posts-box ul.nav li.active a {
  color: #fff;
  background: linear-gradient(to bottom, #e5017c, #e5017c);
  box-shadow: 0 0 4px rgba(229, 1, 124, 0.2);
}

.info_internal_product_page .characteristics .tab-posts-box .tab-content .tab-pane {
  display: none;
  padding: 10px 10px 10px 10px;
  overflow: hidden;
  box-shadow: 0 0 4px rgba(123, 152, 165, 0.4);
  background: #fff;
  border-radius: 2px;
}

.info_internal_product_page .characteristics .tab-posts-box .tab-content .tab-pane .table tbody tr {
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  justify-content: center;
}

.info_internal_product_page .characteristics .tab-posts-box .tab-content .tab-pane .table tbody tr th {
  padding: 0 0 10px 5px;
}

.info_internal_product_page .characteristics .tab-posts-box .tab-content .tab-pane .table tbody tr th strong {
  font-weight: 700;
  font-size: 15px;
}

.info_internal_product_page .characteristics .tab-posts-box .tab-content .tab-pane .table tbody tr td {
  padding: 10px 5px;
  width: 400px;
}

.info_internal_product_page .characteristics .tab-posts-box .tab-content .tab-pane .table tbody tr:last-child {
  border: none;
}

.info_internal_product_page .characteristics .tab-posts-box .tab-content .tab-pane .pimg p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0 0 auto;
  padding-bottom: 10px;
}

.info_internal_product_page .characteristics .tab-posts-box .tab-content .tab-pane .pimg .communications {
  padding-top: 10px;
  font-size: 10px;
}

.info_internal_product_page .characteristics .tab-posts-box .accordion-box .accord-elem {
  margin-top: 8px;
}

.info_internal_product_page .characteristics .tab-posts-box .accordion-box .accord-elem .accord-title {
  position: relative;
  display: flex;
  cursor: pointer;
}

.info_internal_product_page .characteristics .tab-posts-box .accordion-box .accord-elem .accord-title span.accord-link {
  position: absolute;
  width: 36px;
  height: 36px;
  top: 0;
  left: 0;
  text-align: center;
  border: 1px solid #eeeeee;
  border-right: none;
  transition: all 0.2s ease-in-out;
}

.info_internal_product_page .characteristics .tab-posts-box .accordion-box .accord-elem .accord-title span.accord-link:after {
  font-family: 'FontAwesome';
  content: '\f0a9';
  font-size: 18px;
  color: #333333;
  line-height: 36px;
  transition: all 0.2s ease-in-out;
}

.info_internal_product_page .characteristics .tab-posts-box .accordion-box .accord-elem.active .accord-title h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  color: #000;
  border: 1px solid #000;
  background: #000;
}

.info_internal_product_page .characteristics .tab-posts-box .accordion-box .accord-elem.active span.accord-link {
  background: #333333;
  border: 1px solid #333333;
  border-right: none;
}

.info_internal_product_page .characteristics .tab-posts-box .accordion-box .accord-elem.active span.accord-link:after {
  content: '\f0ab';
  color: #000;
}

.info_internal_product_page .characteristics .tab-posts-box .accordion-box .accord-content {
  display: none;
  padding-left: 36px;
  overflow: hidden;
}

.info_internal_product_page .characteristics .tab-posts-box .accordion-box .accord-content p {
  margin: 12px 0;
}

.info_internal_product_page .characteristics .tab-posts-box .accordion-box .accord-elem.active .accord-content {
  display: block;
}

.internal_news_page {
  padding-bottom: 80px;
}

.internal_news_page .container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.internal_news_page .container .widgets {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(25% - 30px);
}

.internal_news_page .container .widgets .block_cat {
  margin-bottom: 25px;
}

.internal_news_page .container .widgets .block_cat p {
  background: #e5017c;
  border-bottom: 1px solid #e5017c;
  padding: 0 0 0 10px;
  line-height: 38px;
  width: 55%;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  font-size: 17px;
}

.internal_news_page .container .widgets .block_cat ul.nav {
  padding: 0;
  margin: 0;
}

.internal_news_page .container .widgets .block_cat ul.nav .drop {
  padding: 0;
  margin: 0;
  list-style: none;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 2px 5px 0px rgba(50, 50, 50, 0.2);
}

.internal_news_page .container .widgets .block_cat ul.nav .drop .capt {
  padding: 15px 10px;
  background: linear-gradient(#F5F5F5, #F5F5F5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-size: 14px;
  text-transform: uppercase;
  border-left: 2px solid #e5017c;
}

.internal_news_page .container .widgets .block_cat ul.nav .drop .dropdown {
  padding: 0;
  margin: 0;
  margin: 15px 30px;
}

.internal_news_page .container .widgets .block_cat ul.nav .drop .dropdown li {
  padding: 0;
  margin: 0;
  list-style: none;
  list-style-type: disc;
  color: #e5017c;
  padding: 5px 0;
}

.internal_news_page .container .widgets .block_cat ul.nav .drop .dropdown li a {
  text-decoration: none;
  color: #e5017c;
  font-size: 15px;
}

.internal_news_page .container .widgets .block_cat_mob {
  display: none;
  margin-bottom: 25px;
}

.internal_news_page .container .widgets .block_cat_mob p {
  background: #e5017c;
  border-bottom: 1px solid #e5017c;
  line-height: 38px;
  width: 100%;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  font-size: 17px;
  text-align: center;
}

.internal_news_page .container .widgets .block_cat_mob p .fa {
  padding-left: 5px;
}

.internal_news_page .container .widgets .block_cat_mob ul.nav {
  padding: 0;
  margin: 0;
}

.internal_news_page .container .widgets .block_cat_mob ul.nav .drop {
  padding: 0;
  margin: 0;
  list-style: none;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 2px 5px 0px rgba(50, 50, 50, 0.2);
}

.internal_news_page .container .widgets .block_cat_mob ul.nav .drop .capt {
  padding: 10px 10px;
  background: linear-gradient(#F5F5F5, #F5F5F5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-size: 12px;
  text-transform: uppercase;
  border-left: 2px solid #e5017c;
}

.internal_news_page .container .widgets .block_cat_mob ul.nav .drop .dropdown {
  padding: 0;
  margin: 0;
  display: none;
  margin: 15px 30px;
}

.internal_news_page .container .widgets .block_cat_mob ul.nav .drop .dropdown li {
  padding: 0;
  margin: 0;
  list-style: none;
  list-style-type: disc;
  color: #e5017c;
  padding: 5px 0;
}

.internal_news_page .container .widgets .block_cat_mob ul.nav .drop .dropdown li a {
  text-decoration: none;
  color: #e5017c;
  font-size: 15px;
}

.internal_news_page .container .widgets .wid {
  text-align: center;
  border: 1px solid #e5e5e5;
  line-height: 0;
  padding-bottom: 10px;
}

.internal_news_page .container .widgets .wid h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  background: linear-gradient(#F5F5F5, #F5F5F5);
  color: #333;
  font-size: 18px;
  padding: 20px 0 20px 0;
  margin-bottom: 10px;
}

.internal_news_page .container .widgets .wid a img {
  width: 150px;
}

.internal_news_page .container .widgets .articles {
  border: 1px solid #e5e5e5;
  margin-top: 10px;
}

.internal_news_page .container .widgets .articles h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  background: linear-gradient(#F5F5F5, #F5F5F5);
  color: #333;
  font-size: 18px;
  padding: 10px 0 10px 10px;
}

.internal_news_page .container .widgets .articles .art {
  padding: 8px 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e5e5e5;
}

.internal_news_page .container .widgets .articles .art .name {
  display: block;
  text-decoration: none;
  color: #333;
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 5px;
  margin-bottom: 7px;
  border-bottom: 1px solid #e5e5e5;
}

.internal_news_page .container .widgets .articles .art .more {
  text-align: right;
}

.internal_news_page .container .widgets .articles .art .more a {
  color: #333;
  font-weight: 400;
  background: linear-gradient(#F5F5F5, #F5F5F5);
  padding: 3px 5px;
  border-radius: 30px;
  text-decoration: none;
  font-size: 13px;
}

.internal_news_page .container .news_page {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(75% - 30px);
}

.internal_news_page .container .news_page .caption {
  position: relative;
}

.internal_news_page .container .news_page .caption h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  font-size: 26px;
  line-height: 24px;
  font-weight: 700;
  color: #333;
  text-align: center;
  padding-bottom: 30px;
}

.internal_news_page .container .news_page .caption:before {
  position: absolute;
  content: "";
  background: #333;
  bottom: 45%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 60px;
  height: 2px;
}

.internal_news_page .container .news_page .page {
  display: flex;
  flex-wrap: wrap;
}

.internal_news_page .container .news_page .page .block {
  width: 30%;
  margin: 0 10px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 5px 22px 0px rgba(30, 37, 54, 0.08);
}

.internal_news_page .container .news_page .page .block .img {
  position: relative;
}

.internal_news_page .container .news_page .page .block .img span {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  padding: 3px 10px;
  font-size: 14px;
  background: #e5017c;
  color: #fff;
}

.internal_news_page .container .news_page .page .block .img a {
  text-decoration: none;
  outline: none;
}

.internal_news_page .container .news_page .page .block .img a img {
  width: 100%;
  border-bottom: 4px solid #e5017c;
}

.internal_news_page .container .news_page .page .block .text {
  padding-left: 10px;
  padding-right: 3px;
}

.internal_news_page .container .news_page .page .block .text .new_z {
  text-decoration: none;
}

.internal_news_page .container .news_page .page .block .text .new_z h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  letter-spacing: 0.4px;
  font-weight: 700;
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #333;
}

.internal_news_page .container .news_page .page .block .text p {
  padding-top: 10px;
  border-top: 1px solid #ECECEC;
  font-size: 14px;
}

.internal_news_page .container .news_page .page .block .btn {
  margin-top: auto;
  text-align: center;
}

.internal_news_page .container .news_page .page .block .btn .button {
  display: inline-block;
  margin-top: 25px;
  margin-bottom: 10px;
  background: #e5017c;
  text-decoration: none;
  color: #fff;
  padding: 8px 12px;
  border-radius: 30px;
  transition: all 0.2s ease-in-out;
}

.internal_news_page .container .articles_page {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(75% - 30px);
}

.internal_news_page .container .articles_page .caption {
  position: relative;
}

.internal_news_page .container .articles_page .caption h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  font-size: 26px;
  font-weight: 700;
  color: #333;
  text-align: center;
  padding-bottom: 30px;
}

.internal_news_page .container .articles_page .caption:before {
  position: absolute;
  content: "";
  background: #333;
  bottom: 45%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 60px;
  height: 2px;
}

.internal_news_page .container .articles_page .cloud {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.internal_news_page .container .articles_page .cloud a {
  text-decoration: none;
  color: #333;
  background: #F5F5F5;
  margin: 3px 2px;
  padding: 5px 15px;
  border-radius: 30px;
  transition: all 0.2s ease-in-out;
}

.internal_news_page .container .articles_page .cloud a:hover {
  background: #e5017c;
  color: #fff;
}

.internal_news_page .container .articles_page .page {
  display: flex;
  flex-wrap: wrap;
}

.internal_news_page .container .articles_page .page .block {
  width: 30%;
  margin: 0 10px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 5px 22px 0px rgba(30, 37, 54, 0.08);
}

.internal_news_page .container .articles_page .page .block .img a {
  text-decoration: none;
  outline: none;
}

.internal_news_page .container .articles_page .page .block .img a img {
  width: 100%;
  border-bottom: 4px solid #e5017c;
}

.internal_news_page .container .articles_page .page .block .text {
  padding-left: 10px;
  padding-right: 3px;
}

.internal_news_page .container .articles_page .page .block .text .new_z {
  text-decoration: none;
}

.internal_news_page .container .articles_page .page .block .text .new_z h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  letter-spacing: 0.4px;
  font-weight: 700;
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #333;
}

.internal_news_page .container .articles_page .page .block .text .subject {
  text-decoration: none;
  display: block;
  color: #a9a9a9;
}

.internal_news_page .container .articles_page .page .block .text .subject:hover {
  text-decoration: underline;
}

.internal_news_page .container .articles_page .page .block .text p {
  margin-top: 6px;
  padding-top: 6px;
  border-top: 1px solid #ECECEC;
  font-size: 14px;
}

.internal_news_page .container .articles_page .page .block .btn {
  margin-top: auto;
  text-align: center;
}

.internal_news_page .container .articles_page .page .block .btn .button {
  display: inline-block;
  margin-top: 25px;
  margin-bottom: 10px;
  background: #e5017c;
  text-decoration: none;
  color: #fff;
  padding: 8px 12px;
  border-radius: 30px;
  transition: all 0.2s ease-in-out;
}

.internal_news_page .container .widgets_mod {
  display: none;
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(25% - 30px);
}

.internal_news_page .container .widgets_mod .wid {
  text-align: center;
  border: 1px solid #e5e5e5;
  line-height: 0;
  padding-bottom: 10px;
}

.internal_news_page .container .widgets_mod .wid h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  background: linear-gradient(#F5F5F5, #F5F5F5);
  color: #333;
  font-size: 18px;
  padding: 20px 0 20px 0;
  margin-bottom: 10px;
}

.internal_news_page .container .widgets_mod .wid a img {
  width: 150px;
}

.internal_news_page .container .widgets_mod .articles {
  border: 1px solid #e5e5e5;
  margin-top: 10px;
}

.internal_news_page .container .widgets_mod .articles h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  background: linear-gradient(#F5F5F5, #F5F5F5);
  color: #333;
  font-size: 18px;
  padding: 10px 0 10px 10px;
}

.internal_news_page .container .widgets_mod .articles .art {
  padding: 8px 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e5e5e5;
}

.internal_news_page .container .widgets_mod .articles .art .name {
  display: block;
  text-decoration: none;
  color: #333;
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 5px;
  margin-bottom: 7px;
  border-bottom: 1px solid #e5e5e5;
}

.internal_news_page .container .widgets_mod .articles .art .more {
  text-align: right;
}

.internal_news_page .container .widgets_mod .articles .art .more a {
  color: #333;
  font-weight: 400;
  background: linear-gradient(#F5F5F5, #F5F5F5);
  padding: 3px 5px;
  border-radius: 30px;
  text-decoration: none;
  font-size: 13px;
}

.internal_news_page .button {
  padding-top: 40px;
}

.internal_news_page .button ul {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

.internal_news_page .button ul li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.internal_news_page .button ul li span {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #888;
  text-transform: uppercase;
  margin: 0px 3px 7px 3px;
  font-size: 14px;
  line-height: 1;
  font-weight: 700;
  width: 35px;
  height: 35px;
  border: 1px solid #f1f1f1;
  transition: all ease-out 0.2s;
}

.internal_news_page .button ul li a {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #888;
  text-transform: uppercase;
  margin: 0px 3px 7px 3px;
  font-size: 14px;
  line-height: 1;
  font-weight: 700;
  width: 35px;
  height: 35px;
  border: 1px solid #f1f1f1;
  transition: all ease-out 0.2s;
}

.internal_news_page .button ul li a:hover {
  background: #e5017c;
  border-color: #e5017c;
  color: #ffffff;
}

.internal_news_page .button ul li .active {
  background: #e5017c;
  border-color: #e5017c;
  color: #ffffff;
}

.internal_news_page .button ul .btn_next a {
  position: relative;
  padding-left: 18px;
  padding-right: 22px;
  text-transform: none;
}

.internal_news_page .button ul .btn_next a:after {
  position: absolute;
  top: 35%;
  left: 76%;
  font-weight: normal;
  font-style: normal;
  font-family: FontAwesome;
  border: none;
  font-size: 7px;
  content: "\f105";
  font-size: 12px;
  padding-left: 7px;
  color: #888;
}

.internal_news_page .button ul .btn_next:hover a:after {
  color: #fff;
}

.internal_news_page .button ul .btn_previous a {
  position: relative;
  padding-left: 18px;
  padding-right: 18px;
}

.internal_news_page .button ul .btn_previous a:before {
  font-weight: normal;
  font-style: normal;
  font-family: FontAwesome;
  border: none;
  font-size: 7px;
  content: "\f104";
  font-size: 12px;
  padding-right: 7px;
}

.info_internal_news_page {
  padding-bottom: 100px;
}

.info_internal_news_page .container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  justify-content: center;
}

.info_internal_news_page .container .widgets {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(25% - 30px);
}

.info_internal_news_page .container .widgets .block_cat {
  margin-bottom: 25px;
}

.info_internal_news_page .container .widgets .block_cat p {
  background: #e5017c;
  border-bottom: 1px solid #e5017c;
  padding: 0 0 0 10px;
  line-height: 38px;
  width: 55%;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  font-size: 17px;
}

.info_internal_news_page .container .widgets .block_cat ul.nav {
  padding: 0;
  margin: 0;
}

.info_internal_news_page .container .widgets .block_cat ul.nav .drop {
  padding: 0;
  margin: 0;
  list-style: none;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 2px 5px 0px rgba(50, 50, 50, 0.2);
}

.info_internal_news_page .container .widgets .block_cat ul.nav .drop .capt {
  padding: 15px 10px;
  background: linear-gradient(#F5F5F5, #F5F5F5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-size: 14px;
  text-transform: uppercase;
  border-left: 2px solid #e5017c;
}

.info_internal_news_page .container .widgets .block_cat ul.nav .drop .dropdown {
  padding: 0;
  margin: 0;
  margin: 15px 30px;
}

.info_internal_news_page .container .widgets .block_cat ul.nav .drop .dropdown li {
  padding: 0;
  margin: 0;
  list-style: none;
  list-style-type: disc;
  color: #e5017c;
  padding: 5px 0;
}

.info_internal_news_page .container .widgets .block_cat ul.nav .drop .dropdown li a {
  text-decoration: none;
  color: #e5017c;
  font-size: 15px;
}

.info_internal_news_page .container .widgets .block_cat_mob {
  display: none;
  margin-bottom: 25px;
}

.info_internal_news_page .container .widgets .block_cat_mob p {
  background: #e5017c;
  border-bottom: 1px solid #e5017c;
  line-height: 38px;
  width: 100%;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  font-size: 17px;
  text-align: center;
}

.info_internal_news_page .container .widgets .block_cat_mob p .fa {
  padding-left: 5px;
}

.info_internal_news_page .container .widgets .block_cat_mob ul.nav {
  padding: 0;
  margin: 0;
}

.info_internal_news_page .container .widgets .block_cat_mob ul.nav .drop {
  padding: 0;
  margin: 0;
  list-style: none;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 2px 5px 0px rgba(50, 50, 50, 0.2);
}

.info_internal_news_page .container .widgets .block_cat_mob ul.nav .drop .capt {
  padding: 10px 10px;
  background: linear-gradient(#F5F5F5, #F5F5F5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-size: 12px;
  text-transform: uppercase;
  border-left: 2px solid #e5017c;
}

.info_internal_news_page .container .widgets .block_cat_mob ul.nav .drop .dropdown {
  padding: 0;
  margin: 0;
  display: none;
  margin: 15px 30px;
}

.info_internal_news_page .container .widgets .block_cat_mob ul.nav .drop .dropdown li {
  padding: 0;
  margin: 0;
  list-style: none;
  list-style-type: disc;
  color: #e5017c;
  padding: 5px 0;
}

.info_internal_news_page .container .widgets .block_cat_mob ul.nav .drop .dropdown li a {
  text-decoration: none;
  color: #e5017c;
  font-size: 15px;
}

.info_internal_news_page .container .widgets .wid {
  text-align: center;
  border: 1px solid #e5e5e5;
  line-height: 0;
  padding-bottom: 10px;
}

.info_internal_news_page .container .widgets .wid h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  background: linear-gradient(#F5F5F5, #F5F5F5);
  color: #333;
  font-size: 18px;
  padding: 20px 0 20px 0;
  margin-bottom: 10px;
}

.info_internal_news_page .container .widgets .wid a img {
  width: 150px;
}

.info_internal_news_page .container .widgets .wid #vk_groups {
  padding-top: 15px;
  margin: 0 auto;
}

.info_internal_news_page .container .block {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(75% - 30px);
}

.info_internal_news_page .container .block .data {
  padding-bottom: 10px;
  display: flex;
  justify-content: flex-end;
}

.info_internal_news_page .container .block .data .dt {
  padding: 5px 0 0 10px;
  font-size: 13px;
  color: #222;
  opacity: 0.7;
}

.info_internal_news_page .container .block h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  line-height: 24px;
}

.info_internal_news_page .container .block .cloud {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.info_internal_news_page .container .block .cloud a {
  text-decoration: none;
  color: #333;
  background: #F5F5F5;
  margin: 3px 2px;
  padding: 5px 15px;
  border-radius: 30px;
  transition: all 0.2s ease-in-out;
}

.info_internal_news_page .container .block .cloud a:hover {
  background: #e5017c;
  color: #fff;
}

.info_internal_news_page .container .block .h1 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-size: 2.0em;
  font-weight: normal;
  margin-top: 1em;
  margin-bottom: 0em;
}

.info_internal_news_page .container .block .h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-size: 1.7em;
  line-height: 24px;
  margin: 1.2em 0em 1.2em 0em;
  font-weight: normal;
  text-align: left;
}

.info_internal_news_page .container .block .h3 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-size: 1.4em;
  margin: 1.2em 0em 1.2em 0em;
  font-weight: normal;
}

.info_internal_news_page .container .block p {
  font-size: 1.0em;
  line-height: 1.8em;
  margin: 1.2em 0em 1.2em 0em;
}

.info_internal_news_page .container .block p a {
  text-decoration: none;
  font-weight: bold;
  color: #000;
}

.info_internal_news_page .container .block h4 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  font-size: 1.2em;
  margin: 1.2em 0em 1.2em 0em;
  font-weight: bold;
}

.info_internal_news_page .container .block h5 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  font-size: 1.0em;
  margin: 1.2em 0em 1.2em 0em;
  font-weight: bold;
}

.info_internal_news_page .container .block h6 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  font-size: 0.8em;
  margin: 1.2em 0em 1.2em 0em;
  font-weight: bold;
}

.info_internal_news_page .container .block ul {
  padding: 0;
  margin: 0;
  margin-left: 40px;
}

.info_internal_news_page .container .block ul li {
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 1.0em;
  line-height: 1.8em;
  margin-top: 0.2em;
  margin-bottom: 0.1em;
  list-style-type: disc;
}

.info_internal_news_page .container .block ul li ul {
  padding: 0;
  margin: 0;
}

.info_internal_news_page .container .block ul li ul li {
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 1.0em;
  line-height: 1.8em;
  margin-top: 0.2em;
  margin-bottom: 0.1em;
  list-style-type: circle;
}

.info_internal_news_page .container .block pre {
  font-family: monospace;
  font-size: 1.0em;
}

.info_internal_news_page .container .block .narrow1 {
  width: 100%;
  line-height: 1.3em;
}

.info_internal_news_page .container .block .narrow2 {
  width: 100%;
  line-height: 1.5em;
}

.info_internal_news_page .container .block .img {
  padding-bottom: 10px;
}

.info_internal_news_page .container .block h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  padding-bottom: 30px;
}

.info_internal_news_page .container .block p {
  padding-bottom: 15px;
}

.info_internal_news_page .container .block .photo_gallery {
  padding-bottom: 50px;
}

.info_internal_news_page .container .block .photo_gallery h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  font-size: 17px;
  font-weight: 700;
  text-align: center;
  padding-bottom: 20px;
}

.info_internal_news_page .container .block .photo_gallery .fotorama {
  width: 100%;
}

.info_internal_news_page .container .block .request_call {
  margin: 20px 0;
}

.info_internal_news_page .container .block .request_call h3 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  padding-bottom: 7px;
}

.info_internal_news_page .container .block .request_call ul {
  padding: 0;
  margin: 0;
  margin-left: 0;
}

.info_internal_news_page .container .widgets_mod {
  display: none;
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(25% - 30px);
}

.info_internal_news_page .container .widgets_mod .wid {
  text-align: center;
  border: 1px solid #e5e5e5;
  line-height: 0;
  padding-bottom: 10px;
}

.info_internal_news_page .container .widgets_mod .wid h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  background: linear-gradient(#F5F5F5, #F5F5F5);
  color: #333;
  font-size: 18px;
  padding: 20px 0 20px 0;
  margin-bottom: 10px;
}

.info_internal_news_page .container .widgets_mod .wid a img {
  width: 150px;
}

.info_internal_news_page .container .widgets_mod .articles {
  border: 1px solid #e5e5e5;
  margin-top: 10px;
}

.info_internal_news_page .container .widgets_mod .articles h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  background: linear-gradient(#F5F5F5, #F5F5F5);
  color: #333;
  font-size: 18px;
  padding: 10px 0 10px 10px;
}

.info_internal_news_page .container .widgets_mod .articles .art {
  padding: 8px 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e5e5e5;
}

.info_internal_news_page .container .widgets_mod .articles .art .name {
  display: block;
  text-decoration: none;
  color: #333;
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 5px;
  margin-bottom: 7px;
  border-bottom: 1px solid #e5e5e5;
}

.info_internal_news_page .container .widgets_mod .articles .art .more {
  text-align: right;
}

.info_internal_news_page .container .widgets_mod .articles .art .more a {
  color: #333;
  font-weight: 400;
  background: linear-gradient(#F5F5F5, #F5F5F5);
  padding: 3px 5px;
  border-radius: 30px;
  text-decoration: none;
  font-size: 13px;
}

.info_customer_reviews {
  padding-bottom: 150px;
  position: relative;
}

.info_customer_reviews:before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 5%;
  width: 150px;
  height: 150px;
  background: url(../img/footer_1.png) no-repeat;
}

.info_customer_reviews:after {
  position: absolute;
  content: "";
  bottom: 0;
  right: 5%;
  width: 150px;
  height: 150px;
  background: url(../img/footer_2.png) no-repeat;
}

.info_customer_reviews .container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  justify-content: center;
}

.info_customer_reviews .container .widgets {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(25% - 30px);
}

.info_customer_reviews .container .widgets .block_cat {
  margin-bottom: 25px;
}

.info_customer_reviews .container .widgets .block_cat p {
  background: #e5017c;
  border-bottom: 1px solid #e5017c;
  padding: 0 0 0 10px;
  line-height: 38px;
  width: 55%;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  font-size: 17px;
}

.info_customer_reviews .container .widgets .block_cat ul.nav {
  padding: 0;
  margin: 0;
}

.info_customer_reviews .container .widgets .block_cat ul.nav .drop {
  padding: 0;
  margin: 0;
  list-style: none;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 2px 5px 0px rgba(50, 50, 50, 0.2);
}

.info_customer_reviews .container .widgets .block_cat ul.nav .drop .capt {
  padding: 15px 10px;
  background: linear-gradient(#F5F5F5, #F5F5F5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-size: 14px;
  text-transform: uppercase;
  border-left: 2px solid #e5017c;
}

.info_customer_reviews .container .widgets .block_cat ul.nav .drop .dropdown {
  padding: 0;
  margin: 0;
  margin: 15px 30px;
}

.info_customer_reviews .container .widgets .block_cat ul.nav .drop .dropdown li {
  padding: 0;
  margin: 0;
  list-style: none;
  list-style-type: disc;
  color: #e5017c;
  padding: 5px 0;
}

.info_customer_reviews .container .widgets .block_cat ul.nav .drop .dropdown li a {
  text-decoration: none;
  color: #e5017c;
  font-size: 15px;
}

.info_customer_reviews .container .widgets .block_cat_mob {
  display: none;
  margin-bottom: 25px;
}

.info_customer_reviews .container .widgets .block_cat_mob p {
  background: #e5017c;
  border-bottom: 1px solid #e5017c;
  line-height: 38px;
  width: 100%;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  font-size: 17px;
  text-align: center;
}

.info_customer_reviews .container .widgets .block_cat_mob p .fa {
  padding-left: 5px;
}

.info_customer_reviews .container .widgets .block_cat_mob ul.nav {
  padding: 0;
  margin: 0;
}

.info_customer_reviews .container .widgets .block_cat_mob ul.nav .drop {
  padding: 0;
  margin: 0;
  list-style: none;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 2px 5px 0px rgba(50, 50, 50, 0.2);
}

.info_customer_reviews .container .widgets .block_cat_mob ul.nav .drop .capt {
  padding: 10px 10px;
  background: linear-gradient(#F5F5F5, #F5F5F5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-size: 12px;
  text-transform: uppercase;
  border-left: 2px solid #e5017c;
}

.info_customer_reviews .container .widgets .block_cat_mob ul.nav .drop .dropdown {
  padding: 0;
  margin: 0;
  display: none;
  margin: 15px 30px;
}

.info_customer_reviews .container .widgets .block_cat_mob ul.nav .drop .dropdown li {
  padding: 0;
  margin: 0;
  list-style: none;
  list-style-type: disc;
  color: #e5017c;
  padding: 5px 0;
}

.info_customer_reviews .container .widgets .block_cat_mob ul.nav .drop .dropdown li a {
  text-decoration: none;
  color: #e5017c;
  font-size: 15px;
}

.info_customer_reviews .container .widgets .wid {
  text-align: center;
  border: 1px solid #e5e5e5;
  line-height: 0;
  padding-bottom: 10px;
}

.info_customer_reviews .container .widgets .wid h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  background: linear-gradient(#F5F5F5, #F5F5F5);
  color: #333;
  font-size: 18px;
  padding: 20px 0 20px 0;
  margin-bottom: 10px;
}

.info_customer_reviews .container .widgets .wid a img {
  width: 150px;
}

.info_customer_reviews .container .block {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(75% - 30px);
}

.info_customer_reviews .container .block .caption {
  padding-bottom: 60px;
}

.info_customer_reviews .container .block .caption h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  font-size: 25px;
  line-height: 24px;
  font-weight: 700;
  color: #333;
  text-align: center;
  position: relative;
  padding-bottom: 10px;
}

.info_customer_reviews .container .block .caption h2 span {
  color: #e5017c;
}

.info_customer_reviews .container .block .caption h2:before {
  position: absolute;
  content: "";
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  width: 80px;
  height: 2px;
  background: #e5017c;
}

.info_customer_reviews .container .block .reviews .sld {
  overflow: hidden;
  padding-bottom: 30px;
}

.info_customer_reviews .container .block .reviews .sld .img {
  text-align: center;
  position: relative;
  padding-bottom: 5px;
  margin-bottom: 5px;
  padding-top: 10px;
}

.info_customer_reviews .container .block .reviews .sld .img img {
  padding: 2px;
  margin: 0 auto;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(229, 1, 124, 0.3);
  width: 100px;
  height: auto;
}

.info_customer_reviews .container .block .reviews .sld .img h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  font-weight: 700;
  font-size: 16px;
  color: #e5017c;
  padding: 10px 0 5px 0;
}

.info_customer_reviews .container .block .reviews .sld .img a {
  color: #333;
  text-decoration: none;
}

.info_customer_reviews .container .block .reviews .sld .img p {
  padding: 0 0 5px 0;
}

.info_customer_reviews .container .block .reviews .sld .img:before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 15px;
  right: 0;
  width: 30px;
  height: 2px;
  background: #e5017c;
  margin: 0 auto;
  box-shadow: 0 0 10px rgba(229, 1, 124, 0.3);
}

.info_customer_reviews .container .block .reviews .sld .text {
  padding: 20px 40px;
  border-bottom: 2px solid #E5017C;
  position: relative;
}

.info_customer_reviews .container .block .reviews .sld .text .author {
  padding-top: 10px;
  display: flex;
  justify-content: space-around;
}

.info_customer_reviews .container .block .reviews .sld .text .author .info span {
  font-weight: 700;
}

.info_customer_reviews .container .block .reviews .sld .text .author .info a {
  text-decoration: none;
  color: #e5017c;
}

.info_customer_reviews .container .block .reviews .sld .text .author .more {
  display: flex;
  align-items: center;
}

.info_customer_reviews .container .block .reviews .sld .text .author .more a {
  font-weight: 700;
  padding-right: 5px;
  display: block;
  text-align: center;
  text-decoration: none;
  color: #e5017c;
}

.info_customer_reviews .container .block .reviews .sld .text .author .more .fa {
  color: #e5017c;
}

.info_customer_reviews .container .block .wid {
  display: none;
  text-align: center;
  border: 1px solid #e5e5e5;
  line-height: 0;
  padding-bottom: 10px;
}

.info_customer_reviews .container .block .wid h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  background: linear-gradient(#F5F5F5, #F5F5F5);
  color: #333;
  font-size: 18px;
  padding: 20px 0 20px 0;
  margin-bottom: 10px;
}

.info_customer_reviews .container .block .wid a img {
  width: 150px;
}

.info_customer_reviews .button {
  padding-top: 60px;
}

.info_customer_reviews .button ul {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

.info_customer_reviews .button ul li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.info_customer_reviews .button ul li span {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #888;
  text-transform: uppercase;
  margin: 0px 3px 7px 3px;
  font-size: 14px;
  line-height: 1;
  font-weight: 700;
  width: 35px;
  height: 35px;
  border: 1px solid #f1f1f1;
  transition: all ease-out 0.2s;
}

.info_customer_reviews .button ul li a {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #888;
  text-transform: uppercase;
  margin: 0px 3px 7px 3px;
  font-size: 14px;
  line-height: 1;
  font-weight: 700;
  width: 35px;
  height: 35px;
  border: 1px solid #f1f1f1;
  transition: all ease-out 0.2s;
}

.info_customer_reviews .button ul li a:hover {
  background: #e5017c;
  border-color: #e5017c;
  color: #ffffff;
}

.info_customer_reviews .button ul li .active {
  background: #e5017c;
  border-color: #e5017c;
  color: #ffffff;
}

.info_customer_reviews .button ul .btn_next a {
  position: relative;
  padding-left: 18px;
  padding-right: 22px;
  text-transform: none;
}

.info_customer_reviews .button ul .btn_next a:after {
  position: absolute;
  top: 35%;
  left: 76%;
  font-weight: normal;
  font-style: normal;
  font-family: FontAwesome;
  border: none;
  font-size: 7px;
  content: "\f105";
  font-size: 12px;
  padding-left: 7px;
  color: #888;
}

.info_customer_reviews .button ul .btn_next:hover a:after {
  color: #fff;
}

.info_customer_reviews .button ul .btn_previous a {
  position: relative;
  padding-left: 18px;
  padding-right: 18px;
}

.info_customer_reviews .button ul .btn_previous a:before {
  font-weight: normal;
  font-style: normal;
  font-family: FontAwesome;
  border: none;
  font-size: 7px;
  content: "\f104";
  font-size: 12px;
  padding-right: 7px;
}

.info_bread_crumbs {
  padding-bottom: 40px;
  padding-top: 135px;
}

.info_bread_crumbs .container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  padding: 5px 0;
}

.info_bread_crumbs .container .block {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(100% - 30px);
  margin-top: 10px;
}

.info_bread_crumbs .container .block ul {
  padding: 0;
  margin: 0;
}

.info_bread_crumbs .container .block ul li {
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline-block;
  margin-right: 5px;
  margin-left: 5px;
}

.info_bread_crumbs .container .block ul li a {
  text-decoration: none;
  position: relative;
  padding-right: 10px;
  padding-left: 2px;
  color: #777;
  font-size: 14px;
}

.info_bread_crumbs .container .block ul li a:before {
  position: absolute;
  content: "/";
  top: -5%;
  right: -10%;
}

.info_bread_crumbs .container .block ul li .active {
  color: #e5017c;
}

.info_bread_crumbs .container .block ul li:last-child a:before {
  content: "";
}

.info_personal_information {
  padding: 10px 0 150px 0;
}

.info_personal_information .container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  justify-content: center;
}

.info_personal_information .container .nav_menu {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(33.333333333333336% - 30px);
}

.info_personal_information .container .nav_menu ul {
  padding: 0;
  margin: 0;
}

.info_personal_information .container .nav_menu ul li {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
}

.info_personal_information .container .nav_menu ul li:before {
  content: "";
  background-size: 25px;
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 15px;
}

.info_personal_information .container .nav_menu ul li a {
  outline: 0;
  color: #333;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 20px;
  text-decoration: none;
  padding: 18px 0 18px 10px;
  background: linear-gradient(to right, #ffffff 0, #f7f7f7 100%);
  border-top: 1px solid #e6e6e6;
  position: relative;
  padding-right: 3px;
  margin-left: 30px;
}

.info_personal_information .container .nav_menu ul li img {
  position: absolute;
  left: -23px;
  top: 15px;
  width: 23px;
  margin-right: 8px;
}

.info_personal_information .container .nav_menu ul li .active {
  background: #fff;
  color: #e5017c;
  font-weight: bold;
}

.info_personal_information .container .nav_menu ul li .active:after {
  content: '';
  position: absolute;
  right: -2px;
  top: 0;
  height: 100%;
  width: 4px;
  background-color: #e5017c;
  color: #e5017c;
  font-weight: bold;
}

.info_personal_information .container .nav_menu ul li .active:before {
  content: '';
  position: absolute;
  right: -12px;
  top: 50%;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-left: 10px solid #e5017c;
  border-bottom: 10px solid transparent;
  margin: -11px 0 0;
}

.info_personal_information .container .information {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(66.66666666666667% - 30px);
}

.info_personal_information .container .information .caption {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 15px;
}

.info_personal_information .container .information .caption h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  color: #333;
}

.info_personal_information .container .information .caption .btn a {
  display: inline-block;
  height: 36px;
  line-height: 36px;
  border: 0;
  border-radius: 5px;
  color: #fff;
  background: #333;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
  padding: 0 28px;
  text-decoration: none;
  transition: 0.2s all ease;
}

.info_personal_information .container .information .caption .btn a:hover {
  background: #444;
}

.info_personal_information .container .information .text {
  padding-top: 5px;
}

.info_personal_information .container .information .text .block {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #F7F7F7;
}

.info_personal_information .container .information .text .block .col_p {
  font-size: 16px;
  width: 170px;
}

.info_personal_information .container .information .text .block .title {
  width: 150px;
}

.info_personal_information .container .information .text .block .numer {
  width: 13%;
}

.info_personal_information .container .information .text .block .numer a {
  font-weight: 700;
  color: #333;
}

.info_personal_information .container .information .text .block .numer a:hover {
  color: #e5017c;
}

.info_personal_information .container .information .text .block .date {
  width: 15%;
}

.info_personal_information .container .information .text .block .price_title {
  width: 25%;
  display: flex;
}

.info_personal_information .container .information .text .block .price_title span {
  padding-right: 5px;
}

.info_personal_information .container .information .text .block .status {
  width: 13%;
}

.info_personal_information .container .information .text .block .input_text {
  width: 35%;
}

.info_personal_information .container .information .text .block .input_text input {
  height: 29px;
  width: 100%;
  box-sizing: border-box;
  padding: 4px 5px;
  font-size: 15px;
  line-height: 19px;
  border: 1px solid #c2c2cc;
  border-radius: 3px;
  box-shadow: inset 3px 3px 1px 0 #eeeeef;
  resize: none;
}

.info_personal_information .container .information .text .hist_bl {
  justify-content: space-between;
}

.info_personal_information .container .information .text .block:last-child {
  border: none;
}

.info_personal_information .container .information .text .block_pass {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}

.info_personal_information .container .information .text .block_pass h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  color: #333;
  padding-bottom: 10px;
}

.info_personal_information .container .information .text .block_pass .pass {
  margin-bottom: 10px;
}

.info_personal_information .container .information .text .block_pass .pass p {
  padding-bottom: 3px;
}

.info_personal_information .container .information .text .block_pass .pass input {
  height: 29px;
  width: 35%;
  box-sizing: border-box;
  padding: 4px 5px;
  font-size: 15px;
  line-height: 19px;
  border: 1px solid #c2c2cc;
  border-radius: 3px;
  box-shadow: inset 3px 3px 1px 0 #eeeeef;
  resize: none;
}

.info_personal_information .container .information .text .btn {
  margin-top: 20px;
}

.info_personal_information .container .information .text .btn .btn-link {
  display: inline-block;
  padding: 8px 40px 12px 40px;
  outline: 0;
  border: 1px solid #ececec;
  background: #f1f1f1;
  color: #333;
  font-size: 14px;
  border-radius: 10px;
  margin-bottom: 10px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  border-bottom: 0;
  box-shadow: 3px 3px 7px 0 rgba(200, 200, 200, 0.5), inset 0 -3px 0 0 #c1c1c1;
}

.info_personal_information .container .information .text_discount {
  padding-top: 25px;
}

.info_personal_information .container .information .text_discount .block {
  display: flex;
  align-items: center;
}

.info_personal_information .container .information .text_discount .block .col_p {
  font-size: 18px;
  width: 290px;
}

.info_personal_information .container .information .text_discount .block .col_w {
  display: flex;
  font-size: 15px;
  font-weight: 700;
  background: #e5017c;
  color: #fff;
  border-radius: 5%;
  padding: 2px 20px 1px 20px;
}

#form_modal_registration {
  display: none;
  width: 30%;
  border: 3px solid #e5017c;
}

#form_modal_registration .modal-content_reg {
  text-align: center;
}

#form_modal_registration .modal-content_reg .modal-header {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 20px;
}

#form_modal_registration .modal-content_reg .modal-header h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-weight: 900;
}

#form_modal_registration .modal-content_reg form label {
  display: block;
  text-align: left;
  padding-left: 5px;
  padding-bottom: 5px;
}

#form_modal_registration .modal-content_reg form input {
  width: 95%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  padding: 10px 10px;
  outline: none;
  border: none;
  color: #333;
  font-size: 13px;
  transition: all 0.2s ease-in-out;
  border-radius: 10px;
  text-decoration: none;
  letter-spacing: 0.8px;
  margin-bottom: 10px;
}

#form_modal_registration .modal-content_reg form .modal-footer {
  text-align: center;
  padding-top: 15px;
}

#form_modal_registration .modal-content_reg form .modal-footer button {
  display: inline-block;
  outline: none;
  border: none;
  padding: 8px 30px 7px 30px;
  background: #e5017c;
  color: #fff;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  transition: 0.3s all ease;
  font-family: 'Ubuntu', sans-serif;
  border-radius: 30px;
}

#form_modal_registration .modal-content_reg form .modal-footer button:hover {
  background: #333;
}

#form_modal_registration .modal-content_reg form .modal-footer .info p {
  display: inline-block;
  margin-top: 15px;
  border-bottom: 1px solid #666666;
  cursor: pointer;
  margin-right: 20px;
  line-height: 18px;
  font-size: 14px;
  padding-bottom: 3px;
  transition: 0.2s all ease;
}

#form_modal_registration .modal-content_reg form .modal-footer .info p a {
  text-decoration: none;
  border-bottom: none;
}

#form_modal_registration .modal-content_reg form .modal-footer .info p a:hover {
  border-bottom: none;
}

#form_modal_registration .modal-content_reg form .modal-footer .info p .fa {
  padding-right: 5px;
}

#form_modal_registration .modal-content_reg form .modal-footer .info p:hover {
  color: #e5017c;
  border-bottom: 1px solid #e5017c;
}

#form_modal_registration .modal-content_reg form .modal-footer .info a {
  display: inline-block;
  margin-top: 15px;
  border-bottom: 1px solid #666666;
  cursor: pointer;
  text-decoration: none;
  color: #666666;
  line-height: 18px;
  font-size: 14px;
  padding-bottom: 3px;
  transition: 0.2s all ease;
}

#form_modal_registration .modal-content_reg form .modal-footer .info a .fa {
  padding-right: 5px;
}

#form_modal_registration .modal-content_reg form .modal-footer .info a:hover {
  color: #e5017c;
  border-bottom: 1px solid #e5017c;
}

#form_modal_registration .modal-content_reg form .modal-footer .svk {
  padding-top: 25px;
}

#form_modal_registration .modal-content_reg form .modal-footer .svk .title {
  position: relative;
  box-sizing: border-box;
}

#form_modal_registration .modal-content_reg form .modal-footer .svk .title span {
  display: inline-block;
  padding: 0 15px;
  background-color: #fff;
  position: relative;
  z-index: 2;
}

#form_modal_registration .modal-content_reg form .modal-footer .svk .title:before {
  content: '';
  position: absolute;
  bottom: 43%;
  left: 0;
  height: 1px;
  width: 100%;
  background-color: #e6e6e6;
}

#form_modal_registration .modal-content_reg form .modal-footer .svk .group {
  padding-top: 20px;
  position: relative;
  display: flex;
  justify-content: center;
}

#form_modal_registration .modal-content_reg form .modal-footer .svk .group:before {
  content: '';
  position: absolute;
  top: 11px;
  left: 50%;
  margin-left: -10px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #325cb3;
}

#form_modal_registration .modal-content_reg form .modal-footer .svk .group span:first-child {
  margin-right: -3px;
}

#form_modal_registration .modal-content_reg form .modal-footer .svk .group span:first-child a {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  background-color: #f85a3f;
  padding: 7px 10px 0 10px;
  width: 40px;
}

#form_modal_registration .modal-content_reg form .modal-footer .svk .group span:first-child a .fa {
  color: #fff;
  font-size: 23px;
  line-height: 20px;
}

#form_modal_registration .modal-content_reg form .modal-footer .svk .group span:nth-child(2) a {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  background-color: #325cb3;
  padding: 7px 10px 0 10px;
  width: 40px;
}

#form_modal_registration .modal-content_reg form .modal-footer .svk .group span:nth-child(2) a .fa {
  color: #fff;
  font-size: 23px;
  line-height: 20px;
}

#form_modal_registration .modal-content_reg form .modal-footer .svk .group span:last-child {
  margin-left: -3px;
}

#form_modal_registration .modal-content_reg form .modal-footer .svk .group span:last-child a {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  background-color: #41abe1;
  padding: 7px 10px 0 10px;
  width: 40px;
}

#form_modal_registration .modal-content_reg form .modal-footer .svk .group span:last-child a .fa {
  color: #fff;
  font-size: 23px;
  line-height: 20px;
}

.info_registration {
  padding: 200px 0 150px 0;
}

.info_registration .container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  justify-content: center;
}

.info_registration .container .registration_fields {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(33.333333333333336% - 30px);
}

.info_registration .container .registration_fields .caption h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  font-size: 23px;
  line-height: 24px;
  padding-bottom: 20px;
  color: #333;
  font-weight: 700;
}

.info_registration .container .registration_fields .text .block {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 15px;
}

.info_registration .container .registration_fields .text .block .title {
  width: 170px;
  font-size: 15px;
}

.info_registration .container .registration_fields .text .block .input_text {
  position: relative;
}

.info_registration .container .registration_fields .text .block .input_text input {
  height: 29px;
  width: 100%;
  box-sizing: border-box;
  padding: 4px 5px;
  font-size: 15px;
  line-height: 19px;
  border: 1px solid #c2c2cc;
  border-radius: 3px;
  box-shadow: inset 3px 3px 1px 0 #eeeeef;
  resize: none;
}

.info_registration .container .registration_fields .text .p_legal {
  display: none;
}

.info_registration .container .registration_fields .text .p_legal .input_text .file {
  height: auto;
  width: 184px;
  padding: 0;
  box-shadow: none;
  border: none;
}

.info_registration .container .registration_fields .text .active {
  display: flex;
}

.info_registration .container .registration_fields .text .btn button {
  padding: 9px 35px 11px 35px;
  outline: none;
  border: none;
  background: #f1f1f1;
  color: #333;
  font-size: 14px;
  border-radius: 10px;
  margin-bottom: 10px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  border: 1px solid #ececec;
  border-bottom: 0;
  box-shadow: 3px 3px 7px 0 rgba(200, 200, 200, 0.5), inset 0 -3px 0 0 #c1c1c1;
  margin-top: 50px;
}

.info_registration .container .registration_fields .text .btn button:hover {
  box-shadow: 3px 3px 7px 0 rgba(200, 200, 200, 0.8), inset 0 -3px 0 0 #c1c1c1;
}

.info_registration .container .registration_fields .text .agreement {
  position: absolute;
  content: "";
  top: 30px;
  left: 0;
  line-height: 16px;
  padding-top: 5px;
}

.info_registration .container .registration_fields .text p {
  line-height: 16px;
  padding-top: 5px;
}

.info_registration .container .registration_fields .text p a {
  color: #e5017c;
  outline: none;
}

.info_registration .container .sotsialka {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(58.333333333333336% - 30px);
  border-left: 1px solid #c1c1c1;
  padding-left: 20px;
}

.info_registration .container .sotsialka h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  padding-bottom: 10px;
  color: #333;
}

.info_registration .container .sotsialka p {
  font-size: 16px;
  line-height: 18px;
  padding-bottom: 25px;
  color: #333;
}

.info_registration .container .sotsialka .svk {
  display: inline-flex;
  flex-direction: column;
}

.info_registration .container .sotsialka .svk a {
  display: inline-flex;
  align-items: center;
  position: relative;
  padding-left: 40px;
  padding: 10px 40px 7px 70px;
  border-radius: 5px;
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  text-align: center;
  margin-bottom: 15px;
  box-sizing: border-box;
  transition: 0.2s all ease;
}

.info_registration .container .sotsialka .svk a img {
  position: absolute;
  top: 1px;
  left: 2px;
  width: 20px;
  height: 20px;
  background: #fff;
  padding: 7px 7px;
  border-radius: 3px;
  line-height: 0;
}

.info_registration .container .sotsialka .svk a:nth-child(1) {
  background: #44619d;
  box-shadow: 3px 3px 7px 0 rgba(68, 97, 157, 0.5), inset 0 -3px 0 0 #364e7e;
}

.info_registration .container .sotsialka .svk a:nth-child(1):hover {
  box-shadow: 3px 3px 7px 0 rgba(68, 97, 157, 0.8), inset 0 -3px 0 0 #364e7e;
}

.info_registration .container .sotsialka .svk a:nth-child(2) {
  background: #f85a3f;
  box-shadow: 3px 3px 7px 0 rgba(248, 90, 63, 0.5), inset 0 -3px 0 0 #FA3816;
}

.info_registration .container .sotsialka .svk a:nth-child(2):hover {
  box-shadow: 3px 3px 7px 0 rgba(248, 90, 63, 0.8), inset 0 -3px 0 0 #FA3816;
}

.info_registration .container .sotsialka .svk a:nth-child(3) {
  background: #4285f4;
  box-shadow: 3px 3px 7px 0 rgba(66, 133, 244, 0.5), inset 0 -3px 0 0 #3871d0;
}

.info_registration .container .sotsialka .svk a:nth-child(3):hover {
  box-shadow: 3px 3px 7px 0 rgba(66, 133, 244, 0.8), inset 0 -3px 0 0 #3871d0;
}

.info_password_recovery {
  padding: 250px 0 300px 0;
}

.info_password_recovery .container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.info_password_recovery .container .caption {
  padding-left: 15px;
  padding-bottom: 25px;
}

.info_password_recovery .container .caption h2 {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-weight: normal;
  font-size: 25px;
  color: #333;
  font-weight: 400;
}

.info_password_recovery .container .block {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(100% - 30px);
}

.info_password_recovery .container .block .form .password_recovery .title {
  font-size: 16px;
  padding-bottom: 7px;
}

.info_password_recovery .container .block .form .password_recovery .input_text input {
  height: 29px;
  width: 40%;
  box-sizing: border-box;
  padding: 4px 5px;
  font-size: 15px;
  line-height: 19px;
  border: 1px solid #c2c2cc;
  border-radius: 3px;
  box-shadow: inset 3px 3px 1px 0 #eeeeef;
  resize: none;
}

.info_password_recovery .container .block .form .password_recovery .btn button {
  padding: 9px 35px 11px 35px;
  outline: none;
  border: none;
  background: #f1f1f1;
  color: #333;
  font-size: 14px;
  border-radius: 10px;
  margin-bottom: 10px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  border: 1px solid #ececec;
  border-bottom: 0;
  box-shadow: 3px 3px 7px 0 rgba(200, 200, 200, 0.5), inset 0 -3px 0 0 #c1c1c1;
  margin-top: 30px;
  font-weight: 700;
}

.info_password_recovery .container .block .form .password_recovery .btn button:hover {
  box-shadow: 3px 3px 7px 0 rgba(200, 200, 200, 0.8), inset 0 -3px 0 0 #c1c1c1;
}

@media screen and (max-width: 1100px) {
  .wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }

  .info_header .contact_information {
    display: none;
  }

  .info_header .container {
    display: none;
  }

  .info_header .navbar-header {
    display: flex;
  }

  .info_jevea_water {
    padding-top: 80px;
  }

  .info_partners .container .block {
    width: calc(50% - 30px);
  }

  .info_footer .author:before {
    display: none;
  }

  .info_footer .author:after {
    display: none;
  }

  .info_production .container .list_water .water .block {
    width: 40%;
  }

  .info_production .container .basket_open form .form_goods .input .block:nth-child(1) {
    flex-wrap: wrap;
  }

  .info_production .container .basket_open form .form_goods .input .block:nth-child(1) input:nth-child(1) {
    margin-right: 0;
  }

  .info_production .container .basket_open form .form_goods .input .block:nth-child(1) input:nth-child(2) {
    margin-right: 0;
  }

  .info_production .container .basket_open form .form_goods .input .block:nth-child(2) {
    flex-wrap: wrap;
  }

  .info_production .container .basket_open form .form_goods .input .block:nth-child(2) .select select {
    margin-bottom: 10px;
  }

  .info_production .container .basket_open form .form_goods .input .block:nth-child(2) .select select:first-child {
    margin-bottom: 20px;
  }

  .info_production .container .basket_open form .form_goods .input .block:nth-child(2) .select .cities {
    margin-left: 0;
  }

  .info_production .container .basket_open form .form_goods .input .block:nth-child(2) p {
    bottom: -10px;
  }

  .info_production .container .basket_open form .form_goods .input .block:nth-child(2) input.cities {
    margin-left: 0;
    margin-top: 5px;
    width: 100%;
  }

  .info_production .container .basket_open form .form_goods textarea {
    margin-top: 0;
  }

  .info_production .container .block_cat p {
    width: 96%;
  }

  .info_internal_product_page .container .block_cat p {
    width: 96%;
  }

  .info_internal_product_page .container .picture_goods {
    width: calc(25% - 30px);
  }

  .info_internal_product_page .container .product_description {
    width: calc(50% - 30px);
  }

  .info_internal_product_page .characteristics .tab-posts-box {
    width: calc(100% - 30px);
  }

  .info_internal_product_page .characteristics .tab-posts-box {
    margin-top: 50px;
  }

  .info_internal_product_page .characteristics .tab-posts-box ul.nav {
    justify-content: center;
  }

  .internal_news_page .container .widgets .block_cat p {
    width: 96%;
  }

  .internal_news_page .container .news_page .page .block {
    width: 45%;
  }

  .internal_news_page .container .articles_page .page .block {
    width: 45%;
  }

  .info_internal_news_page .container .widgets .block_cat p {
    width: 96%;
  }

  .info_customer_reviews .container .widgets .block_cat p {
    width: 96%;
  }

  .info_bread_crumbs {
    padding-top: 60px;
    padding-bottom: 20px;
  }

  .info_registration {
    padding-top: 80px;
  }

  .info_registration .container .registration_fields {
    width: calc(50% - 30px);
  }

  .info_registration .container .sotsialka {
    width: calc(41.66666666666667% - 30px);
  }
}

@media screen and (max-width: 960px) {
  .wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }

  .info_living_crystal .container .block h1 {
    font-size: 24px;
    line-height: 27px;
  }

  .info_background_video .container .block .text p {
    font-size: 24px;
  }

  .info_advantages_drinking_water .container .block {
    width: calc(50% - 30px);
  }

  .info_natural_mineral_composition .container .block {
    width: calc(50% - 30px);
  }

  .info_how_order .container .block {
    width: calc(100% - 30px);
  }

  .info_how_order .container .block_catalog {
    width: calc(100% - 30px);
  }

  .info_stats .container .block {
    margin-bottom: 25px;
  }

  .info_block_slider .container .block .block_sld .slick-dots li {
    width: 7px;
    height: 7px;
  }

  .info_certificates .container .block .block_certifi .slick-dots li {
    width: 7px;
    height: 7px;
  }

  .info_partners .container .block {
    width: calc(58.333333333333336% - 30px);
  }

  .info_partners .container .block_partners .slick-dots li {
    width: 7px;
    height: 7px;
  }

  .info_footer .container .block_logo {
    width: calc(100% - 30px);
  }

  .info_footer .container .block {
    width: calc(33.333333333333336% - 30px);
  }

  .info_footer .container .block:nth-child(3) {
    width: calc(33.333333333333336% - 30px);
  }

  .info_footer .container .block:nth-child(4) {
    width: calc(33.333333333333336% - 30px);
  }

  .info_footer .container .block_logo {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 40px;
  }

  .contact-section .yandex-map-cart .caption p {
    width: 80%;
  }

  .contact-section .contact-info .info {
    width: calc(50% - 30px);
  }

  .contact-section .contact-info .info {
    margin-top: 30px;
    text-align: center;
  }

  .contact-section .contact-info .info ul.information-list {
    margin-top: 15px;
  }

  .contact-section .contact-info .form {
    width: calc(83.33333333333334% - 30px);
  }

  .contact-section .contact-info .form {
    margin-top: 20px;
  }

  .contact-section .contact-info .form h2 {
    font-size: 20px;
  }

  .info_slider_section .container .slick-dots li {
    width: 7px;
    height: 7px;
  }

  .info_production .container .block_cat {
    width: calc(33.333333333333336% - 30px);
  }

  .info_production .container .list_water {
    width: calc(66.66666666666667% - 30px);
  }

  .info_production .container .basket_open {
    width: calc(66.66666666666667% - 30px);
  }

  .info_production .container .basket_open form thead .product-name {
    display: none;
  }

  .info_internal_product_page .container .block_cat {
    width: calc(33.333333333333336% - 30px);
  }

  .info_internal_product_page .container .picture_goods {
    width: calc(16.666666666666668% - 30px);
  }

  .info_internal_product_page .container .product_description {
    width: calc(50% - 30px);
  }

  .internal_news_page .container .widgets {
    width: calc(33.333333333333336% - 30px);
  }

  .internal_news_page .container .news_page {
    width: calc(66.66666666666667% - 30px);
  }

  .internal_news_page .container .articles_page {
    width: calc(66.66666666666667% - 30px);
  }

  .internal_news_page .container .widgets_mod {
    width: calc(33.333333333333336% - 30px);
  }

  .info_internal_news_page .container .widgets {
    width: calc(33.333333333333336% - 30px);
  }

  .info_internal_news_page .container .block {
    width: calc(66.66666666666667% - 30px);
  }

  .info_internal_news_page .container .widgets_mod {
    width: calc(33.333333333333336% - 30px);
  }

  .info_customer_reviews .container .widgets {
    width: calc(33.333333333333336% - 30px);
  }

  .info_customer_reviews .container .block {
    width: calc(66.66666666666667% - 30px);
  }

  .info_customer_reviews .container .block .reviews .sld .text {
    padding: 20px 10px;
  }

  .info_personal_information .container .information .caption h2 {
    padding: 0;
    margin: 0;
    font-size: 100%;
    font-weight: normal;
    font-size: 20px;
  }

  .info_personal_information .container .information .caption .btn a {
    height: auto;
    padding: 0 15px;
    font-size: 12px;
  }

  .info_personal_information .container .information .text .block {
    align-items: flex-start;
    flex-direction: column;
  }

  .info_personal_information .container .information .text .block .title {
    padding-bottom: 5px;
  }

  .info_personal_information .container .information .text .block .col_p {
    padding-bottom: 5px;
  }

  .info_personal_information .container .information .text .hist_bl {
    align-items: center;
    flex-direction: row;
  }

  .info_personal_information .container .information .text .hist_bl .title {
    padding-bottom: 5px;
  }

  .info_personal_information .container .information .text .hist_bl .col_p {
    padding-bottom: 5px;
  }

  .info_personal_information .container .information .hist_text {
    overflow-x: scroll;
  }

  .info_personal_information .container .information .hist_text .block {
    min-width: 600px;
  }

  .info_registration .container .registration_fields {
    width: calc(100% - 30px);
  }

  .info_registration .container .registration_fields .text .block {
    display: block;
  }

  .info_registration .container .registration_fields .text .btn button {
    margin-top: 20px;
  }

  .info_registration .container .sotsialka {
    width: calc(100% - 30px);
  }

  .info_registration .container .sotsialka {
    border-left: none;
    border-top: 1px solid #c1c1c1;
    padding-left: 0;
    padding-top: 20px;
    margin-top: 20px;
  }

  .info_password_recovery {
    padding: 100px 0 100px 0;
  }

  .info_password_recovery .container .block .form .password_recovery .input_text input {
    width: 100%;
    margin-top: 15px;
  }
}

@media screen and (max-width: 780px) {
  .wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }

  #form_modal {
    width: 90%;
  }

  #form_modal {
    width: 95%;
  }

  .info_jevea_water .caption h2 {
    padding-top: 0;
    line-height: 24px;
  }

  .info_living_crystal .container .block h1 {
    font-size: 20px;
    line-height: 24px;
  }

  .info_advantages_drinking_water .caption h2 {
    font-size: 24px;
  }

  .background_substrate {
    height: 150px;
  }

  .info_natural_mineral_composition .caption {
    padding-bottom: 40px;
  }

  .info_natural_mineral_composition .caption h2 {
    font-size: 24px;
  }

  .info_natural_mineral_composition .container .block_img {
    width: calc(50% - 30px);
  }

  .background_underlay {
    height: 150px;
  }

  .info_how_order .caption h2 {
    font-size: 24px;
  }

  .background_payment_prices {
    height: 150px;
  }

  .info_payment_prices .caption h2 {
    font-size: 24px;
  }

  .info_payment_prices .container .block {
    width: calc(100% - 30px);
  }

  .background_terms_delivery {
    height: 150px;
  }

  .info_terms_delivery .caption {
    padding-bottom: 20px;
  }

  .info_terms_delivery .caption h2 {
    font-size: 24px;
  }

  .info_terms_delivery .container .block {
    width: calc(100% - 30px);
  }

  .background_news {
    height: 150px;
  }

  .info_news .caption {
    padding-bottom: 40px;
  }

  .info_news .caption h2 {
    font-size: 24px;
  }

  .info_news .container .block {
    margin-bottom: 25px;
  }

  .background_articles {
    height: 150px;
  }

  .info_stats .caption {
    padding-bottom: 40px;
  }

  .info_stats .caption h2 {
    font-size: 24px;
  }

  .background_reviews_water {
    height: 150px;
  }

  .info_block_slider .caption {
    padding-bottom: 40px;
  }

  .info_block_slider .caption h2 {
    font-size: 24px;
  }

  .info_block_slider .container .block {
    width: calc(100% - 30px);
  }

  .info_block_slider .container .block .block_sld .slick-dots li {
    width: 5px;
    height: 5px;
  }

  .background_certificates {
    background-position: bottom center;
    height: 150px;
  }

  .info_certificates .caption {
    padding-bottom: 40px;
  }

  .info_certificates .caption h2 {
    font-size: 24px;
  }

  .info_certificates .container .block .block_certifi .slick-dots li {
    width: 5px;
    height: 5px;
  }

  .background_partners {
    height: 150px;
  }

  .info_partners .caption {
    padding-bottom: 40px;
  }

  .info_partners .caption h2 {
    font-size: 24px;
  }

  .info_partners .container .block {
    width: calc(66.66666666666667% - 30px);
  }

  .info_partners .container .block_partners .slick-dots li {
    width: 5px;
    height: 5px;
  }

  .info_footer .container .block {
    width: calc(50% - 30px);
  }

  .info_footer .container .block:nth-child(3) {
    width: calc(50% - 30px);
  }

  .info_footer .container .block:nth-child(4) {
    width: calc(100% - 30px);
  }

  .info_footer .container .block:nth-child(4) {
    text-align: center;
  }

  .info_footer .container .block:nth-child(4) h2 {
    padding-top: 30px;
  }

  .info_footer .container .block:nth-child(2) {
    text-align: center;
  }

  .info_footer .container .block:nth-child(3) {
    text-align: center;
  }

  .contact-section .map_yan {
    height: 250px;
  }

  .contact-section .map_yan #yandex-map-cart {
    height: 100%;
  }

  .contact-section .yandex-map-cart .caption p {
    width: 98%;
  }

  .contact-section .contact-info .info {
    width: calc(66.66666666666667% - 30px);
  }

  .contact-section .contact-info .form {
    width: calc(100% - 30px);
  }

  .contact-section .contact-info .form #feedback-form .input {
    flex-wrap: wrap;
  }

  .contact-section .contact-info .form #feedback-form .input input:nth-child(1) {
    margin-right: 0;
  }

  .contact-section .contact-info .form #feedback-form .input input:nth-child(2) {
    margin-left: 0;
  }

  .contact-section .contact-info .form #feedback-form .input input:nth-child(2) {
    margin-right: 0;
  }

  .info_slider_section .container .slick-dots li {
    width: 5px;
    height: 5px;
  }

  .info_production .container .block_cat {
    width: calc(100% - 30px);
  }

  .info_production .container .block_cat {
    display: none;
  }

  .info_production .container .block_cat_mob {
    width: calc(100% - 30px);
  }

  .info_production .container .block_cat_mob {
    display: block;
  }

  .info_production .container .list_water {
    width: calc(100% - 30px);
  }

  .info_production .container .list_water .water {
    justify-content: space-around;
  }

  .info_production .container .basket_open {
    width: calc(100% - 30px);
  }

  .info_production .container .basket_open form .note {
    padding: 15px 10px 0 10px;
  }

  .info_production .container .basket_open form .form_goods .input {
    flex-wrap: wrap;
  }

  .info_production .container .basket_open form .form_goods .input input:nth-child(1) {
    margin-right: 0;
  }

  .info_production .container .block_cat {
    margin-bottom: 40px;
  }

  .info_production .container .block_cat p {
    width: 97%;
    text-align: center;
  }

  .info_internal_product_page .container .block_cat {
    width: calc(100% - 30px);
  }

  .info_internal_product_page .container .block_cat {
    display: none;
  }

  .info_internal_product_page .container .block_cat_mob {
    width: calc(100% - 30px);
  }

  .info_internal_product_page .container .block_cat_mob {
    display: block;
  }

  .info_internal_product_page .container .picture_goods {
    width: calc(25% - 30px);
  }

  .info_internal_product_page .container .picture_goods {
    margin-top: 20px;
  }

  .info_internal_product_page .container .product_description {
    width: calc(75% - 30px);
  }

  .info_internal_product_page .container .product_description {
    margin-top: 40px;
  }

  .info_internal_product_page .characteristics .tab-posts-box .tab-content .tab-pane .table tbody tr td {
    width: 330px;
  }

  .internal_news_page .container .widgets {
    width: calc(100% - 30px);
  }

  .internal_news_page .container .widgets .block_cat {
    display: none;
  }

  .internal_news_page .container .widgets .block_cat {
    margin-bottom: 40px;
  }

  .internal_news_page .container .widgets .block_cat p {
    width: 97%;
    text-align: center;
  }

  .internal_news_page .container .widgets .block_cat_mob {
    display: block;
  }

  .internal_news_page .container .widgets .wid {
    display: none;
  }

  .internal_news_page .container .widgets .articles {
    display: none;
  }

  .internal_news_page .container .news_page {
    width: calc(100% - 30px);
  }

  .internal_news_page .container .articles_page {
    width: calc(100% - 30px);
  }

  .internal_news_page .container .widgets_mod {
    display: block;
  }

  .internal_news_page .container .widgets_mod {
    width: calc(100% - 30px);
  }

  .info_internal_news_page .container .widgets {
    width: calc(100% - 30px);
  }

  .info_internal_news_page .container .widgets .block_cat {
    display: none;
  }

  .info_internal_news_page .container .widgets .block_cat_mob {
    display: block;
  }

  .info_internal_news_page .container .widgets .block_cat {
    margin-bottom: 40px;
  }

  .info_internal_news_page .container .widgets .block_cat p {
    width: 97%;
    text-align: center;
  }

  .info_internal_news_page .container .widgets .wid {
    display: none;
  }

  .info_internal_news_page .container .block {
    width: calc(100% - 30px);
  }

  .info_internal_news_page .container .widgets_mod {
    display: block;
  }

  .info_internal_news_page .container .widgets_mod {
    width: calc(100% - 30px);
  }

  .info_customer_reviews .container .widgets {
    width: calc(100% - 30px);
  }

  .info_customer_reviews .container .widgets .block_cat {
    display: none;
  }

  .info_customer_reviews .container .widgets .block_cat {
    margin-bottom: 40px;
  }

  .info_customer_reviews .container .widgets .block_cat p {
    width: 97%;
    text-align: center;
  }

  .info_customer_reviews .container .widgets .block_cat_mob {
    display: block;
  }

  .info_customer_reviews .container .widgets .wid {
    display: none;
  }

  .info_customer_reviews .container .block {
    width: calc(100% - 30px);
  }

  .info_customer_reviews .container .block .wid {
    display: block;
  }

  .info_bread_crumbs .container .block ul li a {
    font-size: 12px;
    padding-right: 7px;
    padding-left: 0;
  }

  .info_bread_crumbs .container .block ul li a:before {
    position: absolute;
    content: "/";
    top: -23%;
    right: -10%;
  }

  .info_personal_information .container .nav_menu {
    width: calc(100% - 30px);
  }

  .info_personal_information .container .information {
    width: calc(91.66666666666667% - 30px);
  }

  .info_personal_information .container .information .caption {
    flex-wrap: wrap;
    padding-top: 40px;
  }

  .info_personal_information .container .information .caption h2 {
    padding: 0;
    margin: 0;
    font-size: 100%;
    font-weight: normal;
    font-size: 20px;
    padding-bottom: 10px;
  }

  .info_personal_information .container .information .caption .btn a {
    height: auto;
    padding: 0 15px;
    font-size: 12px;
  }

  .info_personal_information .container .information .text .block_pass .pass input {
    width: 100%;
  }

  #form_modal_registration {
    width: 90%;
  }

  #form_modal_registration .modal-content_reg .modal-header h2 {
    font-size: 18px;
  }
}

@media screen and (max-width: 560px) {
  .wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }

  #form_modal .modal-content .modal-header {
    border: none;
  }

  #form_modal .modal-content .modal-header h2 {
    font-size: 18px;
    padding-bottom: 20px;
  }

  #form_modal .modal-content .policy {
    display: none;
  }

  #form_modal .modal-content .policy_tel {
    display: block;
  }

  #form_modal .modal-footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  #form_modal .modal-footer .btn {
    padding-top: 10px;
  }

  .info_background_video #video-bg video {
    display: none;
  }

  .info_background_video #video-bg img {
    display: block;
    width: 100%;
    height: auto;
  }

  .info_background_video .container .block {
    top: 8%;
  }

  .info_background_video .container .block .text {
    padding: 0 10px 0 10px;
  }

  .info_background_video .container .block .text p {
    font-size: 16px;
    line-height: 23px;
  }

  .info_background_video .container .callmeback {
    top: 78%;
  }

  .info_background_video .container .callmeback a {
    padding: 3px 20px;
  }

  .info_background_video {
    box-shadow: 8px 8px 8px -5px rgba(50, 50, 50, 0);
    margin-bottom: 0;
    height: 220px;
  }

  .info_advantages_drinking_water .container .block {
    width: calc(100% - 30px);
  }

  .info_natural_mineral_composition .container .block {
    width: calc(100% - 30px);
  }

  .info_natural_mineral_composition .container .block_img {
    margin-top: 0;
    margin-bottom: 20px;
  }

  .info_how_order .container .block {
    width: calc(100% - 30px);
  }

  .info_how_order .container .block_catalog {
    width: calc(100% - 30px);
  }

  .info_news .container .block {
    width: calc(100% - 30px);
  }

  .info_stats .container .block {
    width: calc(100% - 30px);
  }

  .info_partners .container .block {
    width: calc(100% - 30px);
  }

  .info_footer .container .block {
    width: calc(100% - 30px);
  }

  .info_footer .container .block:nth-child(3) {
    width: calc(100% - 30px);
  }

  .info_footer .container .block:nth-child(3) {
    text-align: center;
    padding-top: 30px;
    margin: 0 auto;
  }

  .info_footer .container .block:nth-child(3) .social_network {
    justify-content: center;
  }

  .info_footer .container .block:nth-child(3) .liveinternet {
    justify-content: center;
  }

  .info_footer .author h2 {
    font-size: 12px;
    letter-spacing: 0.5px;
  }

  .contact-section .contact-info .info {
    width: calc(100% - 30px);
  }

  .info_production .container .list_water .caption:before {
    bottom: 26%;
  }

  .info_production .container .list_water .water .block {
    width: 100%;
  }

  .info_production .container .basket_open form thead .product-remove {
    width: 2%;
  }

  .info_production .container .basket_open form thead .product-price {
    width: 35%;
  }

  .info_production .container .basket_open form thead .product-quantity .number input {
    width: 25px;
  }

  .info_production .container .basket_open form thead .product-quantity {
    width: 50%;
  }

  .info_production .container .basket_open form thead .product-quantity .number .packing {
    display: block;
  }

  .info_production .container .basket_open form thead .product-subtotal {
    width: 5%;
  }

  .info_production .container .basket_open form .form_goods .input .block:nth-child(2) .select {
    width: 100%;
  }

  .info_internal_product_page .container .picture_goods {
    width: calc(100% - 30px);
  }

  .info_internal_product_page .container .picture_goods .caption {
    display: block;
  }

  .info_internal_product_page .container .product_description {
    width: calc(100% - 30px);
  }

  .info_internal_product_page .container .product_description .caption {
    display: none;
  }

  .info_internal_product_page .container .product_description .block_price {
    display: block;
    text-align: center;
  }

  .info_internal_product_page .container .product_description .enter_area .block {
    display: block;
  }

  .info_internal_product_page .container .product_description .enter_area .block .area {
    text-align: center;
  }

  .info_internal_product_page .container .product_description .enter_area .block .basket {
    text-align: center;
    margin-top: 10px;
  }

  .info_internal_product_page .characteristics .tab-posts-box ul.nav {
    display: block;
  }

  .info_internal_product_page .characteristics .tab-posts-box ul.nav li {
    margin-bottom: 5px;
    margin-right: 0;
  }

  .info_internal_product_page .characteristics .tab-posts-box ul.nav {
    flex-wrap: wrap;
    justify-content: center;
  }

  .info_internal_product_page .characteristics .tab-posts-box ul.nav li a {
    padding: 8px 20px;
    font-size: 12px;
  }

  .info_internal_product_page .characteristics .tab-posts-box .tab-content .tab-pane .table tbody tr td {
    width: 200px;
  }

  .info_internal_product_page .characteristics .tab-posts-box .tab-content .tab-pane .table tbody tr {
    justify-content: flex-start;
  }

  .info_internal_product_page .characteristics .tab-posts-box .tab-content .tab-pane .pimg {
    flex-direction: column;
  }

  .info_internal_product_page .characteristics .tab-posts-box .tab-content .tab-pane .pimg img {
    margin-left: 0;
    order: -1;
    margin-top: 15px;
    width: 100%;
  }

  .internal_news_page .container .news_page .page .block .img span {
    display: none;
  }

  .internal_news_page .container .news_page .page .block {
    width: 100%;
  }

  .internal_news_page .container .articles_page .page .block {
    width: 100%;
  }

  .info_customer_reviews {
    padding-bottom: 200px;
  }

  .info_customer_reviews:before {
    left: 2%;
  }

  .info_customer_reviews:after {
    right: 2%;
  }

  .info_personal_information .container .information .caption {
    flex-wrap: wrap;
  }

  .info_personal_information .container .information .caption h2 {
    padding: 0;
    margin: 0;
    font-size: 100%;
    font-weight: normal;
    width: 100%;
    text-align: center;
    display: block;
    margin: 0 auto;
    font-size: 20px;
    padding-bottom: 20px;
  }

  .info_personal_information .container .information .caption .btn {
    margin: 0 auto;
  }

  .info_personal_information .container .information .caption .btn a {
    height: auto;
    padding: 0 15px;
    font-size: 12px;
    margin-bottom: 5px;
  }

  .info_personal_information .container .information .text .block {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .info_personal_information .container .information .text .block .col_p {
    width: 100%;
    padding-bottom: 0;
  }

  .info_personal_information .container .information .text .block:last-child {
    border-bottom: none;
  }

  #form_modal_registration .modal-footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  #form_modal_registration .modal-footer .btn {
    padding-top: 10px;
  }

  .info_registration .container .registration_fields .text .block {
    flex-wrap: wrap;
  }

  .info_registration .container .registration_fields .text .block .title {
    padding-bottom: 5px;
  }
}

@media screen and (max-width: 360px) {
  .wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }

  .info_background_video .container .block {
    top: 20%;
  }

  .info_background_video .container .block .text p {
    font-size: 16px;
    line-height: 18px;
  }

  .info_background_video .container .block .text p span {
    font-weight: 40;
    font-size: 19px;
  }

  .info_background_video .container .callmeback {
    top: 75%;
  }

  .info_background_video .container .callmeback a {
    padding: 3px 20px;
  }

  .info_partners:before {
    left: 3%;
  }

  .info_partners:after {
    right: 3%;
  }

  .info_internal_product_page .characteristics .tab-posts-box .tab-content .tab-pane .table tbody tr td {
    width: 150px;
  }

  .internal_news_page .container .news_page .caption:before {
    display: none;
  }
}

@media print {
  .mappp {
    display: block;
  }

  .caption h3 {
    padding: 0;
    margin: 0;
    font-size: 100%;
    font-weight: normal;
    text-align: center;
    padding-top: 7px;
  }

  .info {
    display: block;
  }

  .info .information-list {
    overflow: hidden;
    margin-top: 45px;
  }

  .info .information-list li {
    list-style: none;
    border-bottom: 1px solid #dddddd;
    margin-bottom: 15px;
  }

  .info .information-list li i {
    float: left;
    display: inline-block;
    color: #333333;
    font-size: 13px;
    line-height: 24px;
    margin-left: 3px;
  }

  .info .information-list li span {
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 8px;
    margin-left: 25px;
    color: #333333;
  }

  .info .information-list li a {
    text-decoration: none;
    font-size: 14px;
    color: #333333;
    font-weight: 400;
    line-height: 20px;
    margin-left: 10px;
    margin-bottom: 10px;
  }

  .info .information-list li:last-child {
    border-bottom: none;
  }
}